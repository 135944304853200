import React, {useState, useEffect} from "react";
import clsx from "clsx";
import {ClickAwayListener, Input, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {selectThesaurusFlatTree} from "../../store/reducers/treeThesaurusSlice";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import tdb from "../../services/TranslateDB";

const KEY_ENTER = 13;
const KEY_ESC = 27;

export default function EditableThesaurus({multiple, initValue, rootId, field, label, textClasses, inputClasses, onChange, variant, inputVariant}) {
    const [editing, setEditing] = useState(false);
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const thesaurusTree = useSelector(state => selectThesaurusFlatTree(state, rootId));
    const initThesaurus = multiple
        ?   thesaurusTree.filter(thesaurus => initValue.indexOf(thesaurus.id) > -1)
        :   thesaurusTree.find(thesaurus => thesaurus.id === initValue);

    const [value, setValue] = React.useState(initThesaurus);
    const handleValue = (event, newValue) => {
        // setValue(newValue);
        //if (!multiple) {
        setEditing(false);
        onChange(newValue, field);
        //}
    }

    const handleEdit = () => setEditing(true);
    const handleUnEdit = () => {
        setEditing(false);
        onChange(value, field);
    }

    const clickAway = () => handleUnEdit();

    const handleKey = (event) => {
        if (event.keyCode === KEY_ENTER) {
            handleUnEdit();
        }
        else if (event.keyCode === KEY_ESC) {
            setValue(initValue);
            setEditing(false);
        }
    };

    return <>
        {!editing
            ? <Typography onClick={handleEdit} classes={textClasses}
                          variant={variant}>{label}</Typography>
            : <ClickAwayListener onClickAway={clickAway}>
                <div>
                    <Autocomplete
                        size="small"
                        multiple={multiple}
                        onKeyUp={handleKey}
                        value={value}
                        onChange={handleValue}
                        disablePortal={true}
                        getOptionLabel={option => tdb(option.title)}
                        renderInput={(params) => <TextField {...params} classes={clsx(inputClasses, classes.autocomplete)} variant={inputVariant} />}
                        renderOption={option => (
                            <div style={{paddingLeft: 6 + option.level * 16}}>
                                {tdb(option.title)}
                            </div>
                        )}
                        classes={{
                            paper: classes.acPaper,
                            option: classes.acOption
                        }}
                        options={thesaurusTree}
                    />
                </div>
            </ClickAwayListener>}</>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    base: {
        borderWidth: 0,
        padding:0
    },
    input: {
        padding: 0
    },
    acPaper: {
        backgroundColor: '#eee',
    },
    acOption: {
        fontSize: '0.8rem',
        padding:1
    },
    autocomplete: {
        options: {
            padding: 2
        }
    }
}));