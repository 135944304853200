import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
// MATERIAL
import {
    Paper, IconButton, Button, Switch, Grid, Box, Typography,
    Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
    List, ListItem, ListItemIcon, ListItemText,
    TextField, Menu, MenuItem
} from "@material-ui/core";
import {
    Cancel,
    Delete,
    DeleteForever,
    DeleteForeverOutlined,
    DeleteOutlineRounded,
    Remove,
    RemoveCircle,
    Close as CloseIcon,
    MoreVert as MoreVertIcon,
    Collections as CollectionsIcon,
    NavigateNextRounded,
    NavigateNext,
    Category, NavigateBefore
} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
// SLICES
import Image from "material-ui-image";
import {selectImageById}                                        from "../../store/reducers/imagesSlice";
import {selectObjectById, deleteObject, addToCollection}        from "../../store/reducers/objectsSlice";
import {selectImagesByObject}                                   from "../../store/reducers/imagesSlice";
import {selectTreeThesaurusEntities, selectTreeThesaurusIds}    from "../../store/reducers/treeThesaurusSlice";
import {selectTreeCategoryById}                                 from "../../store/reducers/treeCategoriesSlice";
import {selectInheritedFieldsByCategory}                        from "../../store/reducers/treeFieldsSlice";
import {selectThesaurusFlatTree}                                from "../../store/reducers/treeThesaurusSlice";
import {selectAllFieldTypes}                                    from "../../store/reducers/fieldTypesSlice";
import {updateObject}                                           from '../../store/reducers/objectsSlice'
import {fetchCollectionById, fetchCollections, selectAllCollections, selectCollectionById, updateCollection} from "../../store/reducers/collectionsSlice";
import {selectAllCollectionTypes, selectCollectionTypeById, selectCollectionTypeIds} from "../../store/reducers/collectionTypesSlice";
import {selectCollectionFieldsOfType} from "../../store/reducers/collectionTypesFieldsSlice";
// CUSTOM
import {ObjectData, ObjectField, ObjectThesaurus}   from "./CollectionFields";
import EditableText from "../Misc/EditableText";
import EditableCategory from "../Misc/EditableCategory";
import EditableThesaurus from "../Misc/EditableThesaurus";
import tdb from "../../services/TranslateDB";
import WithRightButtons from "../Misc/WithRightButtons";

// ---------------------------------------------------------------------------------------------------------------------
// OBJECT THUMBNAIL
export default function CollectionObject({objectId, imageSize, displayMode, editing, action}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();
    const object = useSelector(state => selectObjectById(state, objectId));
    const images = useSelector(state => selectImagesByObject(state, objectId));
    const category = useSelector(state => selectTreeCategoryById(state, object.category));

    const [currentImage, setCurrentImage] = useState(0);
    const handleCurrentImage = () => setCurrentImage((currentImage + 1) % images.length);

    // const nothing = () => {}
    // const clickAction = editing ? nothing : action;
    // const doubleClickAction = editing ? action : nothing;

    const getMappedSize = () => {
        return imageSize * 25 + 50;
    };

    return (
        <>
            <Paper className={classes.image}>
                <div>
                    <Typography style={{width: getMappedSize(), height: '3rem'}}>
                        {category ? tdb(category.title, category.name) : t('Unclassified')}
                    </Typography>
                    <span>
                        {images.length
                            ? <Image
                                alt=""
                                src={images[currentImage].urls.medium}
                                draggable={false}
                                className={classes.media}
                                style={{width: getMappedSize(), height: getMappedSize()}}
                                onDoubleClick={action ? () => action(objectId) : () => {}}
                                onClick={handleCurrentImage}
                            />
                            : <div
                                className={classes.media}
                                style={{width: getMappedSize(), height: getMappedSize()}}
                            >
                            </div>
                        }
                    </span>
                </div>
            </Paper>
        </>
    );
}

// ---------------------------------------------------------------------------------------------------------------------
// OBJECT DIALOG
export function ObjectDialog({collectionId, handleClose, next, prev}) {
    const {objectId} = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();

    const [editing, setEditing] = useState(false);
    const handleEditing = (event, result) => setEditing(result);

    const object = useSelector(state => selectObjectById(state, objectId));
    const category = useSelector(state => selectTreeCategoryById(state, object ? object.category : null));

    const [deleteMenu, setDeleteMenu] = useState(false);
    const handleOpenDeleteMenu = () => setDeleteMenu(true);
    const handleCloseDeleteMenu = () => setDeleteMenu(false);
    const handleDelete = (full) => {
        dispatch(deleteObject({
            objectId: objectId
        }))
    }

    const [copyMenu, setCopyMenu] = useState(false);
    const handleOpenCopyMenu = () => setCopyMenu(true);
    const handleCloseCopyMenu = () => setCopyMenu(false);

    if (!object)return <div>Loading</div>;

    return <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
    >
        <DialogTitle>
            <WithRightButtons
                actions={
                    <>
                        <IconButton onClick={() => prev(objectId)}>
                            <NavigateBefore />
                        </IconButton>
                        <IconButton onClick={() => next(objectId)}>
                            <NavigateNext />
                        </IconButton>
                        <Switch
                            value={editing}
                            name={"edit"}
                            onChange={handleEditing}
                            //className={classes.closeButton}
                        />
                    </>
                    }
            >
                <Typography variant={"h5"}>
                    {tdb(object.title, object.name)}
                </Typography>
            </WithRightButtons>
        </DialogTitle>
        <DialogContent>
            {/* Images and Fields object */}
            <FullObject objectId={objectId} editing={editing} />
            {/* Collections object */}
            {object.collections.map(objectCollectionId => {
                return <ObjectCollection collectionId={objectCollectionId} objectId={objectId} currentCollection={collectionId} />;
            })}
        </DialogContent>
        <DialogActions style={{borderTop: '1px solid #ccc'}}>
            <Box flexGrow={1}>
                <Button variant={"contained"} color={"default"} onClick={handleOpenDeleteMenu}>
                    {t('DeleteObject')}
                </Button>
                <Dialog open={deleteMenu} onClose={handleCloseDeleteMenu}>
                    <DialogTitle>Delete</DialogTitle>
                    <List>
                        <ListItem button color="secondary" onClick={handleDelete}>
                            <ListItemIcon><Delete /></ListItemIcon>
                            <ListItemText>{t('DeleteObjectAndLeaveImages')}</ListItemText>
                        </ListItem>
                        <ListItem button onClick={handleDelete}>
                            <ListItemIcon><DeleteForever /></ListItemIcon>
                            <ListItemText>{t('DeleteObjectAndImage')}</ListItemText>
                        </ListItem>
                        <ListItem button onClick={handleCloseDeleteMenu}>
                            <ListItemIcon><Cancel /></ListItemIcon>
                            <ListItemText>{t('Cancel')}</ListItemText>
                        </ListItem>
                    </List>
                </Dialog>
            </Box>
            <Box>
                <Button variant={"contained"} color={"default"} onClick={handleOpenCopyMenu}>
                    {t('CopyToOtherCollection')}
                </Button>
                <ObjectCopyToCollection objectId={objectId} open={copyMenu} onClose={handleCloseCopyMenu} />
                <Button variant={"contained"} color={"default"}>
                    {t('identifyObject')}
                </Button>
            </Box>
        </DialogActions>
    </Dialog>
}

// ---------------------------------------------------------------------------------------------------------------------
// OBJECT DATA (without collections)
function FullObject({objectId, editing}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const object = useSelector(state => selectObjectById(state, objectId));
    const images = useSelector(state => selectImagesByObject(state, objectId));
    const category = useSelector(state => selectTreeCategoryById(state, object.category));
    const fields = useSelector(state => selectInheritedFieldsByCategory(state, object.category));
    const types = useSelector(selectAllFieldTypes);
    const [currentImage, setCurrentImage] = useState(0);
    const handleCurrentImage = (index) => setCurrentImage(index)

    // UPDATE OBJECT
    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(updateObject({
            objectId: objectId,
            body: body
        }))
    }

    // UPDATE OBJECT FIELD
    const handleChangeField = (value, field) =>  {
        let body = {fields:{}};
        body.fields[field] = value;
        dispatch(updateObject({
            objectId: objectId,
            body: body
        }))
    }

    console.log(images);

    return <Grid container spacing={2}>
        <Grid item xs={4}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Image animationDuration={500} src={images[currentImage].urls.big} />
                </Grid>
                {images.length > 1 && images.map((image, i)=>{
                    return <Grid item xs={4} onClick={()=>handleCurrentImage(i)}>
                        <Image alt="" src={image.urls.small} draggable={false} className={classes.media} />
                    </Grid>
                })}
            </Grid>
        </Grid>
        <Grid item xs={8}>
            <ObjectData name={t('ObjectCategory')} value={
                editing
                    ?   <EditableCategory
                        initValue={category.id}
                        field={"category"}
                        onChange={handleChange}
                    />
                    :   category.name
            } />
            <ObjectData name={t('DateCreated')} value={category.created} />
            <hr/>
            {fields.map((field, index) => {
                const type = types.find(type => type.id === field.tree_field_type_id)
                return type && type.is_thesaurus
                    ?   <ObjectThesaurus field={field} values={object.fields} editing={editing} update={handleChangeField} />
                    :   <ObjectField field={field} values={object.fields} editing={editing} update={handleChangeField} type={type} />
            })}
        </Grid>
    </Grid>
}

// ---------------------------------------------------------------------------------------------------------------------
// COLLECTION OF OBJECT
function ObjectCollection({collectionId, objectId, currentCollection}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const object = useSelector(state => selectObjectById(state, objectId));

    useEffect(()=>{
        dispatch(state => fetchCollectionById(state, collectionId));
    }, [dispatch, collectionId]);

    if (collection) {
        return <Box className={classes.objectCollection}>
            <WithRightButtons
                actions={<ObjectCollectionActions objectId={objectId} collectionId={collectionId} currentCollection={currentCollection} />}
                content={<Typography variant={"h5"}>{collection.name}</Typography>}
            />
            {collection.fields && Object.entries(collection.fields).map(([fieldName, fieldValue])=>{
                return <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <strong>{fieldName}</strong>
                    </Grid>
                    <Grid item xs={8}>
                        {fieldValue}
                    </Grid>
                </Grid>;
            })}
        </Box>;
    }
    else return null;
}

// CONTEXTUAL MENU OF OBJECT COLLECTION
function ObjectCollectionActions({objectId, collectionId, currentCollection}) {
    const object = useSelector(state => selectObjectById(state, objectId));
    const history = useHistory();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {t} = useTranslation();
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const actions = [];

    // move to other collection only if different
    if (collectionId !== currentCollection) {
        const handleViewInCollection = () => {
            handleClose();
            history.push("/collections/"+collectionId+"/object/"+objectId);
        }
        actions.push(<MenuItem onClick={handleViewInCollection}>
            <ListItemIcon>
                <CollectionsIcon />
            </ListItemIcon>
            <ListItemText>
                {t('seeObjectInCollection')}
            </ListItemText>
        </MenuItem>)
    }

    // remove from collection only if there's anotherone
    if (object.collections.length > 1) {
        actions.push(<MenuItem onClick={handleClose}>
            <ListItemIcon>
                <DeleteOutlineRounded />
            </ListItemIcon>
            <ListItemText>
                {t('removeFromCollection')}
            </ListItemText>
        </MenuItem>);
    }

    if (!actions.length)return null;
    return <div>
        <IconButton aria-label="settings" onClick={handleClick}>
            <MoreVertIcon />
        </IconButton>
        <Menu id="investigation-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {actions.map(action=>action)}
        </Menu>
    </div>
}

// COPY OBJECT TO OTHER COLLECTION MODAL
function ObjectCopyToCollection({objectId, open, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    // const collection = useSelector(state => selectCollectionById(state, collectionId));
    const object = useSelector(state => selectObjectById(state, objectId));

    const status = useSelector(state => state.collections.status);
    const collections = useSelector(selectAllCollections);
    // const collectionTypes = useSelector(selectAllCollectionTypes);
    // const collectionTypeIds = useSelector(selectCollectionTypeIds);

    useEffect(()=>{
        if (status === 'idle')dispatch(fetchCollections(null));
    }, [dispatch, status])

    // TODO : parameters on which collection can be filled
    const filteredCollections = collections.filter(collection => {
        return object.collections.indexOf(collection.id) === -1
    });

    const [destinationId, setDestinationId] = useState();
    const handleDestinationId = (event, value) => setDestinationId(value.id);
    const handleCopy = () => {
        const params = {
            body: {
                collection_hash: destinationId,
                object_hash: objectId
            }
        };
        dispatch(addToCollection(params));
    }

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
            {t('Copy Object')}
        </DialogTitle>
        <DialogContent>
            <Autocomplete
                id={"destinationCollection"}
                label={t("ChooseDestinationCollection")}
                renderInput={(params) => <TextField {...params} title={t("ChooseDestinationCollection")} variant={"outlined"} label={t("ChooseDestinationCollection")} />}
                getOptionLabel={(option) => option.name}
                value={destinationId}
                onChange={handleDestinationId}
                options={filteredCollections} />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleCopy}>Copy</Button>
        </DialogActions>
    </Dialog>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    badge: {

    },
    image: {
        borderStyle: 'hash',
        borderColor: theme.palette.primary.contrastText,
        borderWidth: 1,
        padding: theme.spacing(1),
    },
    objectCollection: {
        marginTop: theme.spacing(3),
        borderTop: "1px dotted #666"

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    }
}));

