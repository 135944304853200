import i18n from "i18next";

export default function tdb(object, def) {
    const language = i18n.language;

    if (!object)return 'undefined';
    else if (language in object) return object[language];
    else if (language.toUpperCase() in object) return object[language.toUpperCase()];
    else if ('default' in object)return object['default'];
    else if (def) {
        return def;
    }
    else if (!def && typeof object === "object") {
        return object[Object.keys(object)[0]];
    }
    else if (!def && typeof object === "string") {
        return object;
    }
    else if (def) {
        return def;
    }
    return i18n.t("Untitled")
}