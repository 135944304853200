import React from 'react';
import {Container, Typography, Icon} from "@material-ui/core";
import {ThemeProvider, createMuiTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import {NavLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Stepper from "@material-ui/core/Stepper/Stepper";
import Step from "@material-ui/core/Step/Step";
import StepLabel from "@material-ui/core/StepLabel/StepLabel";

const QueryCreate = ({title, content}) => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const [source, setSource] = React.useState('text');
    const handleSource = (event) => {
        setSource(event.target.value);
    };

    return  <Card>
        <CardHeader title={t('NewQuery')} />
        <CardContent>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label} color={"secondary"}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </CardContent>
        <CardContent>
            {activeStep === 0
                ? <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <FormControl>
                            <RadioGroup value={source} onChange={handleSource}>
                                <FormControlLabel value="text" control={<Radio />} label="Recherche de texte" />
                                <FormControlLabel value="collection" control={<Radio />} label="Collection" />
                                <FormControlLabel value="images" control={<Radio />} label="Images" />
                                <FormControlLabel value="category" control={<Radio />} label="Typologie" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid xs={8}>

                    </Grid>
                </Grid> : null
            }
            {activeStep === 1
                ? <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <RadioGroup value={source} onChange={handleSource}>
                            <FormControlLabel value="text" control={<Radio />} label="Recherche de texte" />
                            <FormControlLabel value="collection" control={<Radio />} label="Collection" />
                            <FormControlLabel value="images" control={<Radio />} label="Images" />
                            <FormControlLabel value="category" control={<Radio />} label="Typologie" />
                        </RadioGroup>
                    </Grid>
                    <Grid xs={8}>

                    </Grid>
                </Grid> : null
            }
        </CardContent>
        <CardActions>
            <Button
                variant="contained"
                to="/cases"
                component={NavLink}
                color="primary"
            >
                cancel
            </Button>
            <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                color="secondary"
            >
                Back
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
            >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
        </CardActions>
    </Card>
};


function getSteps() {
    return ['Select Sources', 'Select Databases', 'Select Option'];
}

function Wizard() {

}

// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default QueryCreate;