import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';

const collectionTypesAdapter = createEntityAdapter();
const initialState = collectionTypesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all collectionTypes
export const fetchCollectionTypes = createAsyncThunk(
    'collectionTypes/fetchCollectionTypes',
    async (parameters, thunkAPI) => {
        const response = await client.get('/collectionTypes/getAll');
        return response.collectionTypes;
    }
);

export const createCollectionType = createAsyncThunk(
    'collectionTypes/createCollectionType',
    async (parameters, thunkAPI) => {
        const response = await client.post('/collectionTypes/create', parameters);
        return response.collectionType;
    }
);

// THE REDUCERS
const collectionTypesSlice = createSlice({
    name: 'collectionTypes',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchCollectionTypes.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCollectionTypes.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched collectionTypes to the array
            collectionTypesAdapter.upsertMany(state, action.payload);
        },
        [fetchCollectionTypes.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [createCollectionType.fulfilled]: (state, action) => {
            state.status = 'success';
            // Add any fetched collectionTypes to the array
            collectionTypesAdapter.addOne(state, action.payload);
        },
    },
});

// export const {  } = collectionTypesSlice.actions;

export default collectionTypesSlice.reducer

export const {
    selectAll: selectAllCollectionTypes,
    selectById: selectCollectionTypeById,
    selectIds: selectCollectionTypeIds,
} = collectionTypesAdapter.getSelectors((state) => state.collectionTypes);