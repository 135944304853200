import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import {getCriminalCasesFields} from '../../api/criminalCasesFields';

// import {selectCriminalCaseById, fetchCriminalCaseById} from "../../store/reducers/criminalCasesSlice";

const CriminalCaseCreate = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fields = getCriminalCasesFields();
    const handleSubmit = (event, data) => {
        console.log(data);
    };
    return <Card>
        <CardHeader title="Create Criminal Case" />
        <CardContent>
            {/* TODO: form here */}
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        Summary
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="info" label={"Infos"} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="action" label={"Action"} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="victim" label={"Victime"} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="legal" label={"Legal"} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="admin" label={"Admin"} />
                    </Grid>
                </Grid>
                <Button type="submit" variant="contained" color="primary">
                    Create
                </Button>
            </form>
        </CardContent>
   </Card>;
};

function InputGroup({group, label}) {
    const fields = getCriminalCasesFields();
    const groupedField = fields.filter(field => field.group === group);
    return <Card elevation={2}>
        <CardHeader title={label} />
        <CardContent>
            {
                groupedField.map(field => {
                    return <InputField field={field}/>
                })
            }
        </CardContent>
    </Card>;
}

function InputField({field}) {
    const classes = useStyles();
    return <TextField
        id={field.field}
        label={field.label}
        type="text"
        variant="outlined"
        className={classes.input}
    />
}

function DataDisplay({label, value}) {
    return <Grid container>
        <Grid xs={2}>{label}</Grid>
        <Grid xs={4}>{value}</Grid>
    </Grid>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    input: {
        marginTop: ".5rem",
        width: '100%'
    }
}));

export default CriminalCaseCreate;