import React, {useEffect} from 'react';
import {Container, Typography, Icon, createMuiTheme, ThemeProvider} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import defaultLightPalette from "../../services/defaultLightPalette";
import {selectAllQueries, fetchQueries} from "../../store/reducers/queriesSlice";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
    import {NavLink} from "react-router-dom";
import {DataGrid} from "@material-ui/data-grid";
import tdb from "../../services/TranslateDB";


const Queries = () => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.queries.status);
    const queries = useSelector(selectAllQueries);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchQueries(null));
        }
    }, [status, dispatch]);

    const getViewCell = (params) => {
        /* to={'/queries/'+params.value} component={NavLink}*/
        return <Button variant="contained" color="default" size="small">
            {t('See')}
        </Button>
    };

    const columns = [
        {field:"Date", headerName:"Date", flex:1, resizable: false },
        {field:"Nom", headerName:"Nom", flex:2, resizable: false },
        {field:"Theme", headerName:"Theme", flex:2, resizable: false },
        {field:"Typologie", headerName:"Typologie", flex:2, resizable: false },
        {field:"Objets", headerName:"Objets", flex:1, resizable: false },
        {field:"Cibles", headerName:"Cibles", flex:1, resizable: false },
        {field: "id",       headerName: ""      , flex: 1, renderCell: getViewCell, resizable: false }
    ];

    const filteredQueries = queries.map(query => {
        return {
            ...query,
            Nom: tdb(query.Nom),
            Theme: tdb(query.Theme),
            Typologie: tdb(query.Theme),
            Objets: tdb(query.Objets),
            Cibles: tdb(query.Cibles),
        }
    })

    return <Card>
        <CardHeader title={t('Queries')+'!'} />
        <CardContent>
            <div style={{height:window.innerHeight - 300}}>
                <DataGrid columns={columns} rows={filteredQueries} pageSize={20} />
            </div>
        </CardContent>
        <CardActions>
            <Button title={t('CreateNewQuery')} variant="contained" color="secondary" to={"queries/new"} component={NavLink}>
                {t('CreateNewQuery')}
            </Button>
        </CardActions>
    </Card>
};

export default Queries;

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    card: {

    },
    create: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        height: 100
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));