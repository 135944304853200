import React from "react";
import {ThemeProvider, createMuiTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import { Switch, Route, NavLink, useRouteMatch, useParams} from "react-router-dom";
import {useTranslation}     from 'react-i18next';
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import StepButton from "@material-ui/core/StepButton";

import Investigation from "./Investigation.jsx";
import Investigations from "./Investigations.jsx";

const InvestigationsRouter = () => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    // TODO improve breadbrumb
    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme}>
            <Switch>
                <Route exact path="/investigations" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                    </Breadcrumbs>
                    <Investigations/>
                </Route>
                <Route path="/investigations/:investigationId">
                    <InvestigationsBreadCrumbs current={<Typography>Current</Typography>} />
                    <Investigation/>
                </Route>
            </Switch>
        </ThemeProvider>
    </Container>;
};

function InvestigationsBreadCrumbs({current}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    return <Breadcrumbs separator="/">
        <Link to="/" component={NavLink} className={classes.Link}>
            {t('HomePageLink')}
        </Link>
        <Link to="/investigations/" component={NavLink} className={classes.Link}>
            {t('InvestigationsLink')}
        </Link>
        {current && current}
    </Breadcrumbs>
}

// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default InvestigationsRouter;