import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
    current
} from '@reduxjs/toolkit';

import {objectAdded} from "./objectsSlice";
import client from '../../api/client';

// Create EntityAdapter
const collectionsAdapter = createEntityAdapter();
const initialState = collectionsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch Collections
export const fetchCollections = createAsyncThunk(
    'collections/fetchCollections',
    async () => {
        const response = await client.get('/getCollections');
        return response.collections;
    });

// fetch Collection detail
export const fetchCollectionById = createAsyncThunk(
    'collections/fetchCollectionById',
    async (collectionId, thunkAPI) => {
        const response = await client.get('/getCollection/'+collectionId, {});
        return response.collection;
    });

// add Collection
export const createCollection = createAsyncThunk(
    'collections/createCollection',
    async (parameters) => {
        const response = await client.post('/collection/create', parameters.body);
        return response.collection;
    });

export const updateCollection = createAsyncThunk(
    'collections/updateCollection',
    async (parameters) => {
        const response = await client.post('/collection/'+parameters.collectionId, parameters.body);
        return response.collection;
    });

export const removeObject = createAsyncThunk(
    'collections/updateCollection',
    async (parameters) => {
        //const response = await client.post('/collection/removeObject'+parameters.collectionId, parameters.body);
        //return response.collection;
    });

// Creating the slice
const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        collectionUpdated(state, action) {
            const { id, title, content } = action.payload;
            const existingCollection = state.entities[id];
            if (existingCollection) {
                existingCollection.title = title;
                existingCollection.content = content;
            }
        },
    },
    extraReducers: {
        [fetchCollections.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCollections.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            collectionsAdapter.upsertMany(state, action.payload);
        },
        [fetchCollections.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchCollectionById.pending]: (state, action) => {},
        [fetchCollectionById.fulfilled]: (state, action) => {
            collectionsAdapter.upsertOne(state, action.payload);
        },
        [fetchCollectionById.rejected]: (state, action) => {},
        [createCollection.fulfilled]: collectionsAdapter.addOne,
        [updateCollection.fulfilled]: (state, action) => {
            collectionsAdapter.upsertOne(state, action.payload);
        },
    },
});

export const { collectionAdded, collectionUpdated } = collectionsSlice.actions;

export default collectionsSlice.reducer

export const {
    selectAll: selectAllCollections,
    selectById: selectCollectionById,
    selectIds: selectCollectionIds,
} = collectionsAdapter.getSelectors((state) => state.collections);

export const selectCollectionsByUser = createSelector(
    [selectAllCollections, (state, userId) => userId],
    (collections, userId) => collections.filter((collection) => collection.user === userId)
);

export const selectCollectionsByInvestigation = createSelector(
    [selectAllCollections, (state, investigationId) => investigationId],
    (collections, investigationId) => collections.filter((collection) => collection.investigation === investigationId)
);

export const selectCollectionsByCriminalCase = createSelector(
    [selectAllCollections, (state, criminalCaseId) => criminalCaseId],
    (collections, criminalCaseId) => collections.filter((collection) => collection.criminal_case === criminalCaseId)
);

/*
export const selectCollectionRootImages = (state, collectionId) => {
    const collection = state.collections.entities[collectionId];
    return collection.images === undefined ? [] : collection.images.filter(image => image.group === null);
};

export const selectCollectionObjects = (state, collectionId) => {
    const collection = state.collections.entities[collectionId];
    return collection.objects === undefined ? [] : collection.objects;
};
export const selectCollectionGroupImages = createSelector(
    [
        (state, collectionId) => selectCollectionById(collectionId),
        (state, imageId) => imageId
    ],
    (collection, imageId) => collection.images.filter(image => image.group === imageId)
);*/
