import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';

const imagesAdapter = createEntityAdapter();
const initialState = imagesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all images : dont use that directly
export const fetchImages = createAsyncThunk('images/fetchImages', async () => {
    const response = await client.post('/getImages', {});
    return response.images;
});

//
export const fetchOrphanedImages = createAsyncThunk('images/fetchOrphanedImages', async () => {
    const response = await client.post('/getImages', {filter: ["without_collection"]});
    return response.images;
});

// fetch Collection detail
export const fetchCollectionImages = createAsyncThunk(
    'images/fetchCollectionImages',
    async (collectionId) => {
        const response = await client.post('/collection/'+collectionId+'/getImages', {});
        return response.images;
    });

// fetch Collection detail
export const fetchUpdateImage = createAsyncThunk(
    'images/fetchUpdateImage',
    async (parameters) => {
        const response = await client.post('/image/'+parameters.imageId, parameters.body);
        return response.image;
    });

// adding image
export const createImage = createAsyncThunk(
    'images/createImage',
    async (parameters) => {
        const body = {
            image: {
                data: parameters.data,
                originalFileName: parameters.name,
            },
            returnFilter: []
        };
        if (parameters.collectionId) {
            body.collection = parameters.collectionId;
        }
        if (parameters.objectId) {
            body.object = parameters.objectId;
        }
        const response = await client.post('/saveImage', body);
        return response.image;
    });

const imagesSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
        imageAdded(state, action) {
            imagesAdapter.addOne(state, action.payload);
        },
        imageUpdated(state, action) {
            imagesAdapter.upsertOne(state, action.payload);
        },
        imagesFetched(state, action) {
            imagesAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: {
        [fetchImages.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchImages.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched images to the array
            imagesAdapter.upsertMany(state, action.payload);
        },
        [fetchImages.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchCollectionImages.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchUpdateImage.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            imagesAdapter.upsertOne(state, action.payload);
        },
        [fetchCollectionImages.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            imagesAdapter.upsertMany(state, action.payload);
        },
        [fetchCollectionImages.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [createImage.pending]: (state, action) => {
            state.status = 'loading';
            const fake = {
                id: action.meta.requestId,
                binary: action.meta.arg.data,
                meta: {versionOf: null}
            };
            if (action.meta.arg.collectionId) {
                fake.collection_id = action.meta.arg.collectionId;
            }
            if (action.meta.arg.objectId) {
                fake.collection_id = action.meta.arg.collectionId;
                fake.object_id = action.meta.arg.collectionId;
            }
            imagesAdapter.addOne(state, fake);
        },
        [createImage.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            imagesAdapter.removeOne(state, action.meta.requestId);
            imagesAdapter.addOne(state, action.payload);
        },
        [createImage.rejected]: (state, action) => {
            imagesAdapter.removeOne(state, action.meta.requestId);
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

export const { imageAdded, imageUpdated, imagesFetched } = imagesSlice.actions;

export default imagesSlice.reducer

export const {
    selectAll: selectAllImages,
    selectById: selectImageById,
    selectIds: selectImageIds,
} = imagesAdapter.getSelectors((state) => state.images);

export const selectImagesByCollection = createSelector(
    [selectAllImages, (state, collectionId) => collectionId],
    (images, collectionId) => images.filter((image) => image.collection_id === collectionId)
);

export const selectImagesByObject = createSelector(
    [selectAllImages, (state, objectId) => objectId],
    (images, objectId) => images.filter((image) => image.object_id === objectId)
);

export const selectCollectionRootImages = createSelector(
    [selectAllImages, (state, collectionId) => collectionId],
    (images, collectionId) => images.filter((image) => (
        image.collection_id === collectionId
        && image.object_id === null
        && (image.meta.versionOf === null || image.meta.versionOf === undefined || image.meta.leaveIt === true)
    ))
);

export const selectImagesVersions = createSelector(
    [selectAllImages, (state, imageId) => imageId],
    (images, imageId) => images.filter((image) => image.meta.versionOf === imageId)
);

