import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

export default function SimpleTable({columns, rows, classes}) {
    return <table>
        <thead>
        {
            columns.map((column, i) => {
                return <th {...column.style}>
                    {column.label}
                </th>;
            })
        }
        </thead>
        <tbody>
        {
            rows.map((row, index) => {
                return <tr>
                    {
                        columns.map((column, i) => {
                            return <td {...column.style}>
                                {row[column.field]}
                            </td>
                        })
                    }
                </tr>
            })
        }
        </tbody>

    </table>;
}

export function SimpleGrid({columns, rows, spacing, headerClass, dataClass}) {
    return <Box>
        <Grid container spacing={spacing ? spacing : 2}>
        {
            columns.map((column, i) => {
                return <Grid key={i} item {...column.attr}  className={headerClass}>
                    {column.label}
                </Grid>;
            })
        }
        </Grid>
        {
            rows.map((row, index) => {
                console.log(row)
                return <Grid container spacing={spacing ? spacing : 2} style={{paddingBottom: 4, borderTop: "1px solid #ccc"}}>
                    {columns.map((column, i) => {
                      console.log(row)
                    return <Grid item {...column.attr} className={dataClass}>
                        {
                            column.render
                                ?   column.render
                                :   (
                                    column.renderCell
                                        ?   column.renderCell({...row, row: row})
                                        :   row[column.field]
                                )
                        }
                    </Grid>
                })}
                </Grid>
            })
        }
    </Box>;
}
