import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from './components/LayoutPersistent.jsx'
import LayoutIcon from './components/LayoutRemainingIcons.jsx'
import LayoutNoIcon from './components/LayoutPersistent'
import {getCurrentUser, fetchCurrentUser} from './store/reducers/currentUserSlice';
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import {DndProvider} from "react-dnd";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {isMobile} from "react-device-detect";
/**
 * Make sure that current user is properly loaded before displaying layout
 * TODO : better display of loading and errors
 */

export const LOGIN_URL = `http://kc.parcs.solutions/auth/realms/Parcs/protocol/openid-connect/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&response_type=code&scope=openid`;

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const status = useSelector((state) => state.currentUser.status);
  const error = useSelector((state) => state.currentUser.error);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCurrentUser(null));
    }
  }, [status, dispatch]);

  let content;
  switch (status) {
    case "loading": content =
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
    ; break;
    case "success":

      content = isMobile
        ? <DndProvider backend={TouchBackend} options={{
            ignoreContextMenu:true
          }}>
            <LayoutNoIcon />
          </DndProvider>
        : <DndProvider backend={HTML5Backend}>
            <LayoutIcon />
          </DndProvider>;
      break;
    case "failed":
      content = <div>
        <p>User can not be fetched</p>
        <a href={LOGIN_URL}>Login</a>
      </div>;
      window.location = LOGIN_URL
      break;
    default: content = <div>Unknown status : {status}</div>;
  }
  return <ThemeProvider theme={darkTheme}>
    {content}
  </ThemeProvider>;
};

const UserError = () => {
  return <Container>
    <Card>
      <CardContent>
        no User
      </CardContent>
    </Card>
  </Container>;
};

// Building my theme here
const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#000000',
    },
    background: {
      default: '#7c7c7c',
    }
  },
  typography: {
    h1: {
      fontSize: "2.8rem"
    },
    h2: {
      fontSize: "2.4rem"
    },
    h3: {
      fontSize: "2.1rem"
    },
    h4: {
      fontSize: "1.8rem"
    },
    h5: {
      fontSize: "1.5rem"
    },
    h6: {
      fontSize: "1.2rem"
    }
  }
});

export default App;
