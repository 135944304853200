import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';
import {selectAllTasks} from "./tasksSlice";
import {fetchCollectionById} from "./collectionsSlice";
import {fetchInvestigationById, selectInvestigationById} from "./investigationsSlice";

const criminalCasesAdapter = createEntityAdapter();
const initialState = criminalCasesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all criminalCases
export const fetchCriminalCases = createAsyncThunk('criminalCases/fetchCriminalCases', async () => {
    const response = await client.get('/criminalCase/getAll');
    return response.criminalCases;
});

export const fetchCriminalCaseById = createAsyncThunk(
    'criminalCases/fetchCriminalCaseById',
    async (criminalCaseId, thunkAPI) => {
        const response = await client.get('/criminalCase/get/'+criminalCaseId);
        const criminalCase = response.criminalCase;
        if (criminalCase.investigations && criminalCase.investigations.length) {
            criminalCase.investigations.map(investigationId => {
                if (!(investigationId in thunkAPI.getState().investigations.entities)) { // avoid cyclic fetch
                    thunkAPI.dispatch(fetchInvestigationById(investigationId));
                }
            });
        }
        if (criminalCase.collections && criminalCase.collections.length) {
            criminalCase.collections.map(collectionId => {
                thunkAPI.dispatch(fetchCollectionById(collectionId));
                if (!(collectionId in thunkAPI.getState().collections.entities)) {} // if needed
            });
        }
        return criminalCase;
});

export const fetchCriminalCaseUpdate = createAsyncThunk(
    'criminalCases/fetchCriminalCaseUpdate',
    async (parameters, thunkAPI) => {
        const response = await client.post('/criminalCase/'+parameters.criminalCaseId, parameters.body);
        const criminalCase = response.criminalCase;
        if (criminalCase.investigations && criminalCase.investigations.length) {
            criminalCase.investigations.map(investigationId => {
                if (!(investigationId in thunkAPI.getState().investigations.entities)) { // avoid cyclic fetch
                    thunkAPI.dispatch(fetchInvestigationById(investigationId));
                }
            });
        }
        if (criminalCase.collections && criminalCase.collections.length) {
            criminalCase.collections.map(collectionId => {
                thunkAPI.dispatch(fetchCollectionById(collectionId));
                if (!(collectionId in thunkAPI.getState().collections.entities)) {} // if needed
            });
        }
        return criminalCase;
    });

// THE REDUCERS
const criminalCasesSlice = createSlice({
    name: 'criminalCases',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchCriminalCases.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCriminalCases.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched criminalCases to the array
            criminalCasesAdapter.upsertMany(state, action.payload);
        },
        [fetchCriminalCases.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchCriminalCaseById.fulfilled]: (state, action) => {
            criminalCasesAdapter.upsertOne(state, action.payload);
        },
        [fetchCriminalCaseUpdate.fulfilled]: (state, action) => {
            criminalCasesAdapter.upsertOne(state, action.payload);
        }
    },
});

// export const {  } = criminalCasesSlice.actions;

export default criminalCasesSlice.reducer

export const {
    selectAll: selectAllCriminalCases,
    selectById: selectCriminalCaseById,
    selectIds: selectCriminalCaseIds,
} = criminalCasesAdapter.getSelectors((state) => state.criminalCases);

export const selectCriminalCasesByInvestigation = createSelector(
    [selectAllCriminalCases, (state, investigationId) => investigationId],
    (criminalCases, investigationId) => criminalCases.filter((criminalCase) => (
        criminalCase.investigations.indexOf(investigationId) >= -1
    ))
);
