import React, {useEffect, useState} from "react";
import { Switch, Route, NavLink, useRouteMatch, useParams, useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import {useTranslation} from "react-i18next";

import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import {makeStyles} from "@material-ui/core/styles";

import CollectionObject, {ObjectDialog} from "./CollectionObject.jsx";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Image from "material-ui-image/lib/components/Image/Image";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import EditIcon from '@material-ui/icons/Edit';


import {selectImagesByObject} from "../../store/reducers/imagesSlice";
import {selectCollectionById} from "../../store/reducers/collectionsSlice";
import {fetchTreeFields, selectInheritedFieldsByCategory} from "../../store/reducers/treeFieldsSlice";

import {fetchCollectionObjects, selectObjectById, selectObjectsByCollection} from "../../store/reducers/objectsSlice";
import {fetchTreeCategories, selectTreeCategoryById} from "../../store/reducers/treeCategoriesSlice";
import WithRightButtons from "../Misc/WithRightButtons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import AttachFileIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import CollectionObjectDnD from "./DnD/CollectionObjectDnD";

export default function CollectionObjects({collectionId, editing}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const objects    = useSelector(state => selectObjectsByCollection(state, collectionId));
    const objectIds = objects.length ? objects.map(obj => obj.id) : [];

    const [display, setDisplay] = useState('grid');
    const handleDisplay = (event, mode) => setDisplay(mode);

    const [cellSize, setCellSize] = useState(2);
    const handleCellSize = (event, size) => setCellSize(size);

    const [objectModal, setObjectModal] = useState(false);

    // Open modal by pushing history
    const handleModalOpen = (objectId) => {
        history.push("/collections/"+collectionId+"/object/"+objectId);
    }

    // go next
    const handleModalNext = (objectId) => {
        const current = objectIds.indexOf(objectId)
        if (current === -1)return;
        const nextId = current === objectIds.length -1 ? objectIds[0] : objectIds[current+1]
        history.push("/collections/"+collectionId+"/object/"+nextId);
    }

    // go prev
    const handleModalPrev = (objectId) => {
        const current = objectIds.indexOf(objectId)
        if (current === -1)return;
        const nextId = current === 0 ? objectIds[objectIds.length -1] : objectIds[current-1]
        history.push("/collections/"+collectionId+"/object/"+nextId);
    }

    // back to collection
    const handleModalClose = () => {
        history.push("/collections/"+collectionId);
    }

    useEffect(()=>{
        dispatch(fetchCollectionObjects(collectionId));
    }, [collectionId, dispatch]);

    const handleNavigate = (index) => {
        alert(index);
    }

    return <CardContent>
        <Grid direction="row" justify="space-between" alignItems="flex-start" container
              className={classes.zoneBar}>
            <Grid direction="row" justify="flex-start" spacing={2} item container xs>
                <Grid item>
                    <Typography variant="h5">{t('CollectionObjets')}</Typography>
                </Grid>
            </Grid>
            <Grid direction="row" spacing={2} justify="flex-end" item container xs>
                <Grid item>
                    <ToggleButtonGroup size="small" value={display}>
                        <ToggleButton value="grid" size="small"  style={{height: 40}} onClick={handleDisplay}>
                            <ViewComfyIcon />
                        </ToggleButton>
                        <ToggleButton value="full" size="small"  style={{height: 40}} onClick={handleDisplay}>
                            <ArtTrackIcon />
                        </ToggleButton>
                        <ToggleButton value="list" size="small"  style={{height: 40}} onClick={handleDisplay}>
                            <ViewListIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>
                    <Slider
                        style={{width: 100}}
                        className={classes.slider}
                        defaultValue={cellSize}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        onChange={handleCellSize}
                        step={1}
                        marks
                        min={1}
                        max={5}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={2} wrap="wrap" justify="flex-start">
            {objects.map((object, i) => (
                <Grid item key={object.id}>
                    {(editing)
                        ? <CollectionObjectDnD collectionId={collectionId} objectId={object.id}>
                                <CollectionObject
                                objectId={object.id}
                                imageSize={cellSize}
                                editing={editing}
                                displayMode={display}
                                action={handleModalOpen} />
                            </CollectionObjectDnD>
                        : <CollectionObject
                            objectId={object.id}
                            imageSize={cellSize}
                            editing={editing}
                            displayMode={display}
                            action={handleModalOpen}
                        />
                    }
                </Grid>
            ))}
        </Grid>
        <Switch>
            <Route path={`/collections/${collectionId}/object/:objectId`}>
                <ObjectDialog handleClose={handleModalClose} collectionId={collectionId} next={handleModalNext} prev={handleModalPrev} />
            </Route>
        </Switch>
    </CardContent>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    zoneBar: {
        marginBottom: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    slider: {
        color: 'rgba(0,0,0,1)',
    }
}));