const defaultLightPalette = {
    palette: {
        type: 'light',
        primary: {
            main: '#ffffff',
        },
    },
    typography: {
        button: {
            textTransform: 'none'
        },
        h1: {
            fontSize: "2.8rem"
        },
        h2: {
            fontSize: "2.4rem"
        },
        h3: {
            fontSize: "2.1rem"
        },
        h4: {
            fontSize: "1.8rem"
        },
        h5: {
            fontSize: "1.5rem"
        },
        h6: {
            fontSize: "1.2rem"
        }
    }
};

export default defaultLightPalette;