import Box from "@material-ui/core/Box";
import React from "react";

export default function WithRightButtons({content, actions, children}) {
    return <Box display={"flex"}>
        <Box flexGrow={1}>
            {content ? content : children}
        </Box>
        <Box>
            {actions}
        </Box>
    </Box>
}
