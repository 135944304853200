import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation}     from 'react-i18next';

import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import { NavLink} from "react-router-dom";

import { DataGrid } from '@material-ui/data-grid';

import Typography from "@material-ui/core/Typography";
import MaterialTable from 'material-table';

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {getCriminalCasesFields} from '../../api/criminalCasesFields';
import WithRightButtons from "../Misc/WithRightButtons";

import ViewComfy from "@material-ui/icons/ViewComfy";
import ViewModule from "@material-ui/icons/ViewModule";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachFileIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import Menu from "@material-ui/core/Menu";
import Switch from "@material-ui/core/Switch";

import MenuItem from "@material-ui/core/MenuItem";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";

import ToggleButton from "@material-ui/lab/ToggleButton";
import {selectCriminalCaseById, fetchCriminalCaseById, fetchCriminalCaseUpdate} from "../../store/reducers/criminalCasesSlice";
import {fetchInvestigationUpdate, selectInvestigationByCriminalCase} from "../../store/reducers/investigationsSlice";

import {selectCollectionsByCriminalCase} from "../../store/reducers/collectionsSlice";
import Collection from "../Collection/Collection.jsx";
import {fetchSurveys, selectSurveysByCriminalCase} from "../../store/reducers/surveysSlice";
import {fetchQueries} from "../../store/reducers/queriesSlice";

import Chip from "@material-ui/core/Chip";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import WarningIcon from '@material-ui/icons/Warning';
import Surveys from "../Survey/Surveys";
import EditableText from "../Misc/EditableText";

const CriminalCase = ({ criminalCaseId }) => {

    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const criminalCase = useSelector(state => selectCriminalCaseById(state, criminalCaseId));

    const [displayMode, setDisplayMode] = useState('tabs');
    const handleDisplayMode = (event, mode) => {setDisplayMode(mode)};

    const [currentTab, setCurrentTab] = React.useState(0);
    const handleCurrentTab = (event, newValue) => setCurrentTab(newValue);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const [editing, setEditing] = useState(false);
    const handleEditing = (event, newValue) => setEditing(newValue);

    const handleClose = () => setAnchorEl(null);
    const collections = useSelector(state => selectCollectionsByCriminalCase(state, criminalCaseId));

    useEffect(()=>{
        dispatch(fetchCriminalCaseById(criminalCaseId));
    }, [criminalCaseId, dispatch]);

    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(fetchCriminalCaseUpdate({
            criminalCaseId: criminalCaseId,
            body: body
        }))
    }

    if (!criminalCase)return <Container disableGutters={true}>Loading</Container>;

    return <Card className={classes.criminalCase} elevation={3}>
        <CardHeader
            title={
                editing
                    ? <EditableText
                        initValue={criminalCase.origin}
                        onChange={handleChange}
                        field='origin'
                        variant='h5'
                        inputClasses={classes.h5input}
                    />
                    : criminalCase.origin
            }
            action={
                <Switch value={editing} onChange={handleEditing} />
            }
        />
        <CardContent>
            <WithRightButtons
                content={
                    <>
                        <Typography variant="h6">{criminalCase.summary}</Typography>
                        <DataDisplay label={"Origine"} value={criminalCase.origin} />
                     </>
                }
                actions={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                } />
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleClose}>Modify data</MenuItem>
            </Menu>
        </CardContent>
        <CardContent>
            <Card>
                <CardHeader title={('CriminalCaseData')} action={
                    <Grid direction={"row"} container>
                    {displayMode === "tabs" ?
                        <Grid item>
                            <Tabs value={currentTab} onChange={handleCurrentTab} aria-label="simple tabs example">
                                <Tab label="Info" />
                                <Tab label="Action" />
                                <Tab label="Legal" />
                                <Tab label="Admin" />
                            </Tabs>
                        </Grid> : null
                    }
                        <Grid item>
                            <ToggleButtonGroup size="small" exclusive value={displayMode} onChange={handleDisplayMode}>
                                <ToggleButton value="tabs" style={{height: 40}}>
                                    <ViewCompactIcon fontSize="small"/>
                                </ToggleButton>
                                <ToggleButton value="grid" style={{height: 40}}>
                                    <ViewComfy fontSize="small"/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                } />
                <CardContent>
                    {displayMode === "tabs"
                        ? <CriminalCaseTabs data={criminalCase} currentTab={currentTab} />
                        : <CriminalCaseGrid data={criminalCase} />
                    }
                </CardContent>
            </Card>
        </CardContent>
        <InvestigationList criminalCaseId={criminalCaseId}/>
        <CardContent>
            <Surveys criminalCaseId={criminalCaseId} simpleTable={true} />
        </CardContent>
        <CardContent>
            {collections.map(collection => {
                return  <Collection collectionId={collection.id} />
            })}
        </CardContent>
   </Card>;
};

function InvestigationList({criminalCaseId}) {
    const investigations = useSelector(state => selectInvestigationByCriminalCase(state, criminalCaseId));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {t, i18n} = useTranslation();
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    return <CardContent>
        <WithRightButtons
            content={
                <>
                    <Typography>{t("InvestigationsLinkedToCase")}</Typography>
                    <ul>
                        {investigations.map((investigation, i) => {
                            return <li><InvestigationLink investigation={investigation} /></li>;
                        })}
                    </ul>
                </>
            }
            actions={
                <IconButton aria-label="settings" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
            }
        />

        <Menu id="investigation-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleClose}>{t('AttachInvestigation')}</MenuItem>
            <MenuItem onClick={handleClose}>{t('CreateInvestigation')}</MenuItem>
        </Menu>
    </CardContent>;
}


function InvestigationLink({investigation}) {
    return <NavLink to={`/investigations/${investigation.id}`} >
        {investigation.name}
    </NavLink>;
}

function CriminalCaseGrid({data}) {
    return <Grid container spacing={4}>
            <Grid md={6} xs={12} item>
                <DataDisplayGroup object={data} group="info" />
            </Grid>
            <Grid md={6} xs={12} item>
                <DataDisplayGroup object={data} group="action" />
            </Grid>
            <Grid md={6} xs={12} item>
                <DataDisplayGroup object={data} group="legal" />
                <DataDisplayGroup object={data} group="victim" />
            </Grid>
            <Grid md={6} xs={12} item>
                <DataDisplayGroup object={data} group="admin" />
            </Grid>
        </Grid>;
}

function CriminalCaseTabs({data, currentTab}) {
    return <CardContent>
            <TabPanel currentTab={currentTab} index={0}>
                <DataDisplayGroup object={data} group="info" />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={1}>
                <DataDisplayGroup object={data} group="action" />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={2}>
                <DataDisplayGroup object={data} group="legal" />
                <DataDisplayGroup object={data} group="victim" />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={3}>
                <DataDisplayGroup object={data} group="admin" />
            </TabPanel>
        </CardContent>;
}

function TabPanel({children, currentTab, index, ...other}) {
    return <div
        role="tabpanel"
        hidden={currentTab !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {currentTab === index && (
            <Box>
                {children}
            </Box>
        )}
    </div>;
}

function DataDisplayGroup({object, data, group}) {
    const fields = getCriminalCasesFields();
    const groupedField = fields.filter(field => field.group === group);
    return groupedField.map(field => {
        return <DataDisplay label={field.label} value={object[field.field]} />
    });
}

function DataDisplay({label, value}) {
    const classes = useStyles();
    return <Grid item container classes={classes.dataRow}>
        <Grid xs={4} className={classes.label}>{label}</Grid>
        <Grid xs={8} className={classes.value}>{value}</Grid>
    </Grid>;
}

function CrimininalCaseDaisplay({criminalCase}) {
    return <Grid>

    </Grid>;
}

// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette,
    shape: {
        borderRadius: 10
    }
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    criminalCase: {
        backgroundColor: 'rgba(255,255,255,0.8)'
    },
    dataRow: {
        backgroundColor: "#ccc",
        borderTop: '1px solid #e0e0e0'
    },
    label: {
        color: '#666'
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));

export default CriminalCase;