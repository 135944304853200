import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import {makeStyles, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";

import { NavLink} from "react-router-dom";
import { DataGrid } from '@material-ui/data-grid';

import Typography from "@material-ui/core/Typography";
import MaterialTable from 'material-table';

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";

import {selectAllCriminalCases, fetchCriminalCases} from "../../store/reducers/criminalCasesSlice";
import {useTranslation} from "react-i18next";

export default function CriminalCaseList() {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.criminalCases.status);
    const criminalCases = useSelector(selectAllCriminalCases);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchCriminalCases(null));
        }
    }, [status, dispatch]);

    // TODO: choose columns
    const columns = [
        {field: "references",   headerName: "reference" , flex: 1},
        {field: "opening_date",   headerName: "date" ,    flex: 1},
        {field: "summary",      headerName: "Summary"   , flex: 2},
        {field: "action_city",  headerName: "City"      , flex: 2},
        {field: "action_type",  headerName: "Type"      , flex: 1},
        {field: "id",       headerName: ""      , flex: 1,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" size="small" to={'/cases/'+params.value} component={NavLink}>
                    {t('See')}
                </Button>
            )
        }
    ];

    const casesData = [];
    criminalCases.map((criminalCase, index) => {
        let data = {};
        columns.map(column => {
            data[column.field] = criminalCase[column.field];
        });
        casesData.push(data);
    });
    return <Card>
        <CardHeader
            title={t("CriminalCasesTitle")}
        />
        <CardContent>
            <div style={{height:500}}>
                {/*<CriminalCaseTable criminalCases={criminalCases} />*/}
                <DataGrid columns={columns} rows={criminalCases} pageSize={8} />
                {/*<MaterialTable columns={columns} data={casesData} />*/}
            </div>
        </CardContent>
        <CardActions>
            <Button variant="contained" size="medium" color="secondary" component={NavLink} to="/cases/import">
                {t('ImportCriminalCases')}
            </Button>
            <Button variant="contained" size="medium" color="secondary" component={NavLink} to="/cases/new">
                {t('CreateNewCase')}
            </Button>
        </CardActions>
    </Card>
}

// Just if DataGrid dont fit
function CriminalCaseTable({criminalCases}) {
    return <table>
        <thead>
        <tr>
            <th>Num</th>
            <th>Date</th>
            <th>Summary</th>
            <th>City</th>
            <th>Type</th>
        </tr>
        </thead>
        <tbody>
        {criminalCases.map((criminalCase, index) => {
            return <CriminalCaseRow case={criminalCase} />
        })}
        </tbody>
    </table>;
}

function CriminalCaseRow({criminalCase}) {
    return <tr>
        <td>{criminalCase.num_ref}</td>
        <td>{criminalCase.action_place_type}</td>
        <td>{criminalCase.summary}</td>
        <td>{criminalCase.action_city}</td>
        <td>{criminalCase.action_type}</td>
    </tr>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));