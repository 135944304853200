import React, {useState, useEffect, createRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import Typography from "@material-ui/core/Typography";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Tabs from "@material-ui/core/Tabs";
import Badge from "@material-ui/core/Badge";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import IdentificationResults from "./IdentificationResults";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import WithRightButtons from "../Misc/WithRightButtons";
import Container from "@material-ui/core/Container";
import {launchSearch, searchInit, tabNames} from "../../store/reducers/searchSlice";
import {SERVER} from "../../api/client";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ViewComfy from "@material-ui/icons/ViewComfy";
import ViewList from "@material-ui/icons/ViewList";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import EditableThesaurus from "../Misc/EditableThesaurus";
import tdb from "../../services/TranslateDB";
import clsx from "clsx";
import {selectAllTreeThesauruses, fetchTreeThesauruses, selectThesaurusFlatTree} from "../../store/reducers/treeThesaurusSlice";
import {fetchCollectionById} from "../../store/reducers/collectionsSlice";
import {fetchTreeCategories, selectAllTreeCategories} from "../../store/reducers/treeCategoriesSlice";
import {fetchTreeFields} from "../../store/reducers/treeFieldsSlice";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const Identification = () => {
    const dispatch = useDispatch();
    const fileInput = createRef();
    const {t} = useTranslation();
    const [image, setImage] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchMode, setSearchMode] = useState('simple');
    const status = useSelector(state => state.search.status);
    const categories = useSelector(selectAllTreeCategories);

    useEffect(() => {
        if (!categories.length) {
            dispatch(fetchTreeCategories(null));
            dispatch(fetchTreeThesauruses(null));
            dispatch(fetchTreeFields(null));
        }
    }, [categories, dispatch]);

    // nicer way to store file in filereader
    // TODO : add in index
    FileReader.prototype.setFile = function(file) {
        this.file = file;
        return this;
    };
    const handleGetImage = () => {
        if (fileInput.current.files.length === 1) {
            const file = fileInput.current.files[0];
            const reader = new FileReader().setFile(file);
            reader.onload = function(event) {
                setImage({
                    data: 'data:'+this.file.type+';base64,'+window.btoa(this.result),
                    name: this.file.name
                });
            };
            reader.readAsBinaryString(file);
            dispatch(searchInit());
        }
    };

    const handleAdvancedMode = () => setSearchMode('advanced');
    const handleSimpleMode = () => setSearchMode('simple');

    const handleOpenFile = () => {
        fileInput.current.click();
    }
    const handeleSearchTermsChange = (event) => setSearchText(event.target.value);

    const handleLaunchSearch = () => {
        var data = {}
        if (image) {
            data = image // let the middleware make the proper nesting
        }
        data['searchText'] = searchText
        dispatch(launchSearch(data));

    };

    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme}>
            <Card>
                <CardHeader title={"Identification"}/>
                <CardContent style={{marginBottom: '40px'}}>
                    <Grid container spacing={2} justify={"center"}>
                        <Grid md={3} sm={6} xs={12} item>
                        <h3>{t('ImageSearch')}</h3>
                            {
                                image
                                    ?   <img src={image.data} style={{ width: '100%', height: 'auto'}}   />
                                    :   <Box display="flex" alignItems={"stretch"}  style={{cursor: 'pointer', border: '2px dashed #ccc', height: 200, backgroundColor: "#e0e0e0"}} onClick={handleOpenFile}>
                                      <AddAPhotoIcon style={{ margin:' 0 auto', top: '62px', position: 'relative', fontSize: '65px', color: '#999'}} />
                                    </Box>
                            }
                            {searchMode === "simple" ?
                            <>
                            <h3>{t('KeywordsSearch')}</h3>
                            <div><TextField fullWidth variant="standard" label={t('KeywordsSearch')} name="searchTerms" value={searchText} onChange={handeleSearchTermsChange} /></div>
                            </>
                            : null}
                        </Grid>
                        {searchMode === "advanced" && <Grid md={9} sm={6} xs={12} item>
                            <AdvancedFields />
                        </Grid>}
                    </Grid>
                </CardContent>
                <CardActions style={{justifyContent: "center"}}>
                    {!image
                        ?   <Button variant="contained" color="secondary" startIcon={<PhotoCameraIcon />} type="file" component='label' style={{display:"none"}}>
                                <input ref={fileInput} onChange={handleGetImage} type="file" />
                                {t('ChoosePicture')}
                            </Button>
                        :   <Button variant="contained" color="default" startIcon={<PhotoCameraIcon />} type="file" component='label'>
                                <input ref={fileInput} onChange={handleGetImage} type="file" style={{display:"none"}} />
                                {t('ChangePicture')}
                            </Button>
                    }
                    {   searchMode === 'simple'
                        ?   <Button onClick={handleAdvancedMode} color="default" variant="contained">
                                {t('MoreOptions')}
                            </Button>
                        :   <Button onClick={handleSimpleMode} color="default" variant="contained">
                                {t('LessOptions')}
                            </Button>
                    }

                    <Button onClick={handleLaunchSearch} color="secondary" variant="contained">
                        {t('LaunchSearch')}
                    </Button>

                </CardActions>
                {status === "loading"?   <CardContent>
                    <Typography>Loading</Typography>
                </CardContent> : null}
                {status === "failed"?   <CardContent>
                    <Typography>An Error Occured</Typography>
                </CardContent> : null}
                {status === "success"?   <SearchResult /> : null}
            </Card>
        </ThemeProvider>
    </Container>;
};

function AdvancedFields() {
    const {t} = useTranslation();
    const dynamic = true;
    const variant = "standard";
    const sizes = {md:4, sm:12, xs:12}
    return <Grid container spacing={2}>
        <Grid item {...sizes}>
            <TextField fullWidth variant={variant} label={"number"} name={"number"} />
        </Grid>
        <Grid item {...sizes}>
            <TextField fullWidth variant={variant} label={"Name"} name={"name"} />
        </Grid>
        <Grid item {...sizes}>
            <TextField fullWidth variant={variant} label={"Investigation"} name={"investigation"} />
        </Grid>
        <Grid item {...sizes}>
            <TextField fullWidth variant={variant} label={t("Type")} name={"type"} />
            <TextFieldSearch dynamic={dynamic} root={366} fullWidth variant={variant} label={t("Technique")} name={"technique"} />
            <TextFieldSearch dynamic={dynamic} root={452} fullWidth variant={variant} label={t("Inscriptions/markings")} name={"inscription"} />
            <TextFieldSearch dynamic={dynamic} root={518} fullWidth variant={variant} label={t("Distinguish features")} name={"features"} />
        </Grid>
        <Grid item {...sizes}>
            <TextField fullWidth variant={variant} label={t("Title")} name={"title"} />
            <TextField fullWidth variant={variant} label={t("Subject")} name={"subject"} />
            <TextFieldSearch dynamic={dynamic} root={1461} fullWidth variant={variant} label={t("Date/Period")} name={"timespan"} />
            <TextField fullWidth variant={variant} label={t("Maker")} name={"maker"} />
        </Grid>
        <Grid item {...sizes}>
            <TextField fullWidth variant={variant} label={t("Free Text")} name={"text"} multiline />
            <TextField fullWidth variant={variant} label={t("Length")} name={"length"} />
            <TextField fullWidth variant={variant} label={t("Width")} name={"width"} />
            <TextField fullWidth variant={variant} label={t("Depth")} name={"depth"} />
        </Grid>
    </Grid>;
    // return
}

function TextFieldSearch({variant, root, label, name, dynamic}) {
    const thesaurusTree = useSelector(state => selectThesaurusFlatTree(state, root));
    const classes = useStyles();
    const init = {
        id: null,
        title: {fr: label, en: label},
        level: 0
    };
    const [value, setValue] = React.useState([]);
    const handleValue = (event, newValue) => {
        setValue(newValue)
        console.log(newValue);
    }
    return dynamic
        ?   <Autocomplete
                value={value}
                defaultValue={1}
                onChange={handleValue}
                multiple
                getOptionLabel={option => tdb(option.title)}
                renderInput={(params) => <TextField {...params} label={label} name={name} classes={clsx(classes.autocomplete)} variant={variant} />}
                renderOption={option => (
                    <div style={{paddingLeft: option.level * 12}}>
                        {tdb(option.title)}
                    </div>
                )}
                options={thesaurusTree}
            />
        :   <TextField fullWidth variant={variant} label={label} name={name} />
}


function SearchResult() {
    const search = useSelector(state => state.search.search.search);
    const bestMatch = useSelector(state => state.search.search.bestMatch);
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [value, setValue] = React.useState(Object.entries(search).findIndex(([key, content]) => key == bestMatch));
    const [col, setCol] = React.useState(3);
    const [display, setDisplay] = React.useState('list');
    const handleChange = (event, newValue) => setValue(newValue);
    const handleDisplay = (event, newValue) => setDisplay(newValue);


    return <CardContent classes={classes.resultContainer}>
        <Card elevation={5}>
            {/* BROWSER VIEW : TABS */}
            <BrowserView>
                <CardHeader
                    title={
                        <Tabs orientation="horizontal" value={value} onChange={handleChange} aria-label="Vertical tabs" className={classes.tabs}>
                            {Object.entries(search).map(([key, content], index)=> {
                                return tabNames[key].hidden===true ? null : <Tab
                                    disabled={content ? content.length===0 : true}
                                    className={classes.tab}
                                    label={
                                      key=='police_test' ? <Badge badgeContent={content.length} color={"secondary"} showZero={false}>
                                          <Typography>
                                              {tabNames[key] ? t(tabNames[key].label) : key}
                                          </Typography>
                                      </Badge>:
                                      <Typography>
                                          {tabNames[key] ? t(tabNames[key].label) : key} ({content.length})
                                      </Typography>

                                    }
                                    id={`vertical-tabs-${key}`}
                                    aria-controls={`vertical-tabpanel-${index}`} />
                            })
                          }
                        </Tabs>
                    }
                    action={
                        <ToggleButtonGroup size="medium" exclusive value={display} onChange={handleDisplay}>
                            <ToggleButton value="grid" style={{height: 40}}>
                                <ViewComfy fontSize="default"/>
                            </ToggleButton>
                            <ToggleButton value="list" style={{height: 40}}>
                                <ViewList fontSize="default"/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    }
                />
                <CardContent>
                    {Object.entries(search).map(([key, content], index)=> {
                        return <IdentificationResults value={value} index={index} type={key} content={content} display={display} col={col} />
                    })}
                </CardContent>
            </BrowserView>
            {/* MOBILE VIEW : ACCORDION */}
            <MobileView>
                <CardHeader
                    action={
                        <ToggleButtonGroup size="medium" exclusive value={display} onChange={handleDisplay}>
                            <ToggleButton value="grid" style={{height: 40}}>
                                <ViewComfy fontSize="default"/>
                            </ToggleButton>
                            <ToggleButton value="list" style={{height: 40}}>
                                <ViewList fontSize="default"/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    }
                />
                <CardContent style={{padding: 3}}>
                    {Object.entries(search).map(([key, content], index)=> {
                        return tabNames[key].hidden===true ? null :
                            <Accordion disabled={content.length===0}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                >
                                <Badge badgeContent={content.length} color={"secondary"} showZero={false}>
                                    <Typography>
                                        {tabNames[key] ? t(tabNames[key].label) : key}
                                    </Typography>
                                </Badge>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <IdentificationResults value={index} index={index} type={key} content={content} display={display} col={col} />
                                </AccordionDetails>
                            </Accordion>
                    })}
                </CardContent>
            </MobileView>
            {/* ACTIONS */}
            <CardActions>
                <Button variant={"contained"} color={"default"}>
                    {t('RecordThisSearch')}
                </Button>
                <Button variant={"contained"} color={"default"}>
                    {t('MonitorThisSearch')}
                </Button>
            </CardActions>
        </Card>
    </CardContent>;
}



// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette
});


// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    resultContainer: {
        padding: theme.spacing(2)
    },
    tab: {
        borderRight: '1px solid #ccc',
        color: '#000',
        fontSize: "1rem",
        wordWrap: true,
        wrap: true,
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    },
    autocomplete: {
        options: {
            padding: 2
        }
    }
}));

export default Identification;
