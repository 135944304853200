import React from 'react';
import {Container, Typography, Icon} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";

const ContentPage = ({title, content}) => {
    return <Container disableGutters={true}>
        <Card>
            <CardHeader title={title} />
            <CardContent style={{minHeight: 400}}>
                {content}
            </CardContent>
        </Card>
    </Container>
};

export default ContentPage;