import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {SERVER} from "../../api/client_ai";
import Image from "material-ui-image/lib/components/Image/Image";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Rating from "@material-ui/lab/Rating";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import DialogActions from "@material-ui/core/DialogActions";
import {createMuiTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {tabNames} from "../../store/reducers/searchSlice";

const redListPriorities2 = {
    "webOrigin":        {group: "A", order:1},
    "mainType":         {group: "A", order:2},
    "redListTags":      {group: "A", order:3},
    "type":             {group: "A", order:4},
    "keyTerm":          {group: "A", order:5},
    "redListOrigin":    {group: "A", order:6},
    "attachement":      {group: "A", order:7},
    "tags":             {group: "A", order:8},
    "estimatedCulture": {group: "B", order:1},
    "estimatedOrigin":  {group: "B", order:2},
    "provenance":       {group: "B", order:3},
    "provenanceTags":   {group: "B", order:3},
    "function":         {group: "C", order:1},
    "material":         {group: "C", order:2},
    "shape":            {group: "C", order:3},
    "length":           {group: "D", order:1},
    "width":            {group: "D", order:2},
    "depth":            {group: "D", order:3},
    "museum":           {group: "E", order:1},
    "url":              {group: "E", order:2},
    "property":         {group: "E", order:3},
    "id":               {group: "F", order:1},
    "source":           {group: "F", order:2},
    "imageName":        {group: "F", order:3},
    "redListIcom":      {group: "F", order:4},
    "redListNumber":    {group: "F", order:5},
    "image":            {group: "F", order:6},
    "from":             {group: "F", order:7},
    "to":               {group: "F", order:8},
    "features":         {group: "F", order:8},
    "decor":            {group: "F", order:9},
};

const redListPriorities = ['webOrigin', 'redListTags', 'type', 'keyTerm', 'estimatedCulture', 'estimatedOrigin', 'provenance', 'from', 'to', 'material', 'function', 'shape', 'length', 'width', 'depth', 'note1', 'source', 'url', 'note2',];


export default function IdentificationResults({ value, index, type, content, display, col }) {

    if (type === "smartBrowser") {
        content = content.map((found) => {
            return {...found, joinedCategories: found.data.categories.join('/')};
        });
        console.log(content)
        display = 'grid';
        col = 2;
    }

    let list, introduction;
    const specialists = [];
    if (tabNames[type].groupBy) {
        const field = tabNames[type].groupBy;
        const groups = {};
        content.map((found) => {
            const fieldValue = found[field] ? found[field] : found.data[field];
            if (Object.keys(groups).indexOf(fieldValue) === -1)groups[fieldValue] = [];
            groups[fieldValue].push(found);
            return;
        });

        if (type === 'smartBrowser') {
            introduction = `${Object.keys(groups).length} ${tabNames[type].groupName}${Object.keys(groups).length>1?'s':''}`;
        }
        else if (Object.keys(groups).length < content.length) {
            introduction = `${content.length} résultat${content.length>1?'s':''} de ${Object.keys(groups).length} ${tabNames[type].groupName}${Object.keys(groups).length>1?'s':''}`;
        }
        else {
            introduction = `${content.length} résultat${content.length>1?'s':''} de ${Object.keys(groups).length} ${tabNames[type].groupName}${Object.keys(groups).length>1?'s':''}`;
        }

        list = Object.entries(groups).map(([name, groupedList], index)=> {
            return <Grid spacing={3} container>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>{name ? name : null}</Typography>
                    </Grid>
                    {groupedList.map((found, index) => {
                        if (found.data.source) {
                            specialists.push(found.data.source)
                        }
                        return <IdentificationResult type={type} found={found} display={display} col={col}/>
                    })}
                </Grid>
        });


    }
    else {
        introduction = `${content.length} Items`;
        list = <Grid spacing={3} container>
            {content.map((found, index) => {
                if (found.source) {
                    specialists.push(found.source)
                }
                return <IdentificationResult type={type} found={found} display={display} col={col}/>
            })}
        </Grid>;
    }

    const sortFields = () => {

    }

    return <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`}>
        <Grid container spacing={3} justify={"space-between"}>
            <Grid item  flexGrow={1} p={3}>
                <Typography variant={"h6"}>{introduction}</Typography>
            </Grid>
            <Grid item>
                {specialists && specialists.length > 0 ? <ResultSpecialist sources={specialists} /> : null}
            </Grid>
        </Grid>
        {value === index ? list : null}
    </div>;
}

function IdentificationResult({type, found, display, col}) {
    let content;
    switch (type) {
        case "police":     content = <Police     display={display} found={found} />;break;
        // case "police_test":     content = <Police     display={display} found={found} />;break;
        case "ecommerce":          content = <Ecommerce          display={display} found={found} />;break;
        case "redLists":     content = <RedList      display={display} found={found} />;break;
        case "redListInt":   content = <RedListInt   display={display} found={found} />;break;
        case "positive_database":       content = <PositiveDatabase       display={display} found={found} />;break;
        case "smartBrowser": content = <SmartBrowser display={display} found={found} />;break;
        case "looted": content = <Looted display={display} found={found} />;break;
        default : content = <Typography>unknown type '{type}'</Typography>;break;
    }
    return <Grid item xs={display === 'grid' ? col : 12}>
        {content}
    </Grid>;
}

// LAYOUT
function ResultLayout({title, dist, image, content, display, summary}) {
    if (display === 'list') {
        return <Card elevation={3}>
            <CardHeader title={<Typography>{title}</Typography>} action={dist} />
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>{image}</Grid>
                    <Grid item xs={12} md={9}>{content}</Grid>
                </Grid>
            </CardContent>
        </Card>
    }
    else {
        return <Card elevation={3}>
            <CardHeader title={<Typography>{title}</Typography>} action={dist} />
            <CardContent>
                {image}
            </CardContent>
            <CardContent>
                {summary}
            </CardContent>
        </Card>
    }
}

function Police({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((100 - parseFloat(found.dist) * 50));
    return <ResultLayout
        image={<img src={found.img} className={classes.image} />}
        title={""}
        dist={`${dist}%`}
        content={<DisplayDataGrid name={"Data"} data={found} exclude={['img', 'image', 'id', 'dist']}/>}
        display={display}
    />
}

function Looted({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((100 - parseFloat(found.dist) * 50));
    return <ResultLayout
        image={<img src={found.img} className={classes.image} />}
        title={found.data.title}
        dist={`${dist}%`}
        content={<DisplayDataGrid name={"Data"} data={found.data} exclude={['img', 'image', 'id', 'dist']}/>}
        display={display}
    />
}

function Ecommerce({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((100 - parseFloat(found.dist) * 70));
    return <ResultLayout
        title={found.data.subject}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        content={
            <>
                <Link href={found.data.url} target="_blank" style={{color: 'red'}}>{found.data.url}</Link>
                <DisplayDataGrid name={"Description"} data={found.data.body} exclude={[]}/>
                <DisplayDataGridLine name={"Categorie"} value={found.data.category_name} />
                <DisplayDataGridLine name={"Publication"} value={found.data.first_publication_date} />
                <DisplayDataGridLine name={"cityCity"} value={found.data.location.city} />
                <DisplayDataGridLine name={"Country"} value={found.data.location.country_id} />
                <DisplayDataGridLine name={"Region"} value={found.data.location.region_name} />
                <DisplayDataGridLine name={"Seller Name"} value={found.data.location.name} />
                {
                    Array.isArray(found.data.attribute) ? (
                        found.attribute.map(attribute=>{
                            return <DisplayDataGridLine name={attribute.key} value={attribute.value} />
                        })
                    ) : null
                }
            </>
        }
        summary={<Link href={found.url} target="_blank"  style={{color: 'red'}}>{found.url}</Link>}
        display={display}
    />
}

function RedList({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((100 - parseFloat(found.dist) * 50));
    return <ResultLayout
        title={found.data.title}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        //image={<Image src={image}  width="100%" height="auto" />}
        content={<DisplayDataGrid data={found.data} exclude={[
            'attachment',
            'image',
            'id',
            'dist',
            'imageName'
        ]}/>}
        /*'img', 'image', 'id', 'dist'*/
        display={display}
        summary={""}
    />;
}

/**
 * Source
 */
function RedListInt({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((100 - parseFloat(found.dist) * 50));
    return <ResultLayout
        title={found.data.provenanceTags}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        content={<DisplayDataGrid name={"Data"}
                                  data={found.data}
                                  exclude={[]}
                                  excluder={['img', 'image', 'id', 'dist', 'redListIcom', 'redListIcom', 'redListOrigin', 'redListTags']}
                                  show={redListPriorities}
        />}
        display={display}
        summary={""}
    />;
}

function PositiveDatabase({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((100 - parseFloat(found.dist) * 50));
    return <ResultLayout
        title={found.data.titr}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        content={
            <>
                <a href={`https://www.google.com/maps/@${found.data.geolocalisation_ville[1]},${found.data.geolocalisation_ville[0]},14z`} target="_blank" style={{color: 'red'}}>Emplacement</a>
                <DisplayDataGrid name={found.data.datasetid} data={found.data} exclude={['geolocalisation_ville']}/> : null;
            </>
        }
        display={display}
        summary={""}
    />;
}

// DONE
/*
Grouper par category
 */
function SmartBrowser({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((100 - parseFloat(found.dist) * 50));
    return <ResultLayout
        title={""}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        display={display}
        content={""}
        summary={""}
    />;
}



function DisplayDataGridLine({name, value}) {
    return <Grid container>
        <Grid item xs={12} md={3}><strong>{name}</strong></Grid>
        <Grid item xs={12} md={9}>{value}</Grid>
    </Grid>
}

function DisplayDataGrid({name, data, id, exclude, open, show}) {
    const truncateURL = (url) => {
        if  (url.split("?").length > 0) {
            url = url.split("?")[0];
        }
        return url.replace(/(.{20})/g,"$1 ")
    }
    if (exclude.indexOf(name)>=0)return null;
    // STRING
    if (typeof(data) === "string") {
        return <DisplayDataGridLine
            name={name}
            value={
                data.startsWith("http")
                    ?   <div style={{width: '100%', overflow: 'hidden'}}>
                            <a href={data} target="_blank" style={{color: 'red'}}>{truncateURL(data)}</a>
                        </div>
                    :   data
            }
        />
    }
    // NUMBER
    if (typeof(data) === "number") {
        return <DisplayDataGridLine name={name} value={data}/>
    }
    // OBJECT
    else if (data && typeof data === "object" && !Array.isArray(data)) {
        let displayed = [];
        if (show) {
            return <>
                {show.map(field => {
                    if (data[field]) {
                        return <DisplayDataGrid name={field} data={data[field]} exclude={exclude} show={show} />
                    }
                    else {
                        displayed.push(field);
                    }
                })}
            </>;
        }
        return <>
            {Object.entries(data).map(([name, val], index)=> {
                return <DisplayDataGrid name={name} data={val} exclude={exclude} />
            })}
        </>;
    }
    // ARRAY
    else if (data && Array.isArray(data)) {
        return <>
            <label>{name}</label>
            <ul>
                {data.map((val, index)=> {
                    return <li>
                        <DisplayDataGrid name={name} data={val} exclude={exclude} />
                    </li>
                })}
            </ul>
        </>
    }
    // ELSE
    else if (data) {
        return <DisplayDataGridLine name={name} value={data}/>;
    }
    // BIZARRE
    else {
        return <div>{typeof(data)}</div>
    }
}

function ResultSpecialist({sources}) {
    const specialists2 = sources.filter(source => source == "Julie Marchand");
    const specialist1 = specialists2.map(specialist=>{
        return "Lydia Bertrand";
    });
    const specialists = specialist1.filter((v, i, a) => a.indexOf(v) === i);
    const [specialistModal, setSpecialistModal] = useState(false);
    const {t} = useTranslation();
    const handleModalOpen = (specialist) => setSpecialistModal(specialist);
    const handleModalClose = () => setSpecialistModal(false);
    return <Box p={2}>
        {specialists.map((specialist, index) => {
            return <Button
                startIcon={
                    <Avatar src={"https://storage.gra.cloud.ovh.net/v1/AUTH_08039aa9790e44acb556a9f6515b6899/Parcs-Main/b0a22f2e108a8a06/big/011f990ce07a.jpg"} />
                }
                variant={"outlined"} onClick={() => handleModalOpen(specialist)}
            >
                {t('ContactExperts')} : {specialist}
                <Rating name="size-small" defaultValue={4.5} size="small" />
            </Button>;
        })}
        {specialists.map((specialist, index) => {
            return <Dialog
                onClose={handleModalClose}
                open={specialist === specialistModal}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle color={"default"}>Specialiste</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            {/*<Image src={"https://storage.gra.cloud.ovh.net/v1/AUTH_08039aa9790e44acb556a9f6515b6899/Parcs-Main/b0a22f2e108a8a06/big/011f990ce07a.jpg"} />*/}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ExpertData name={"name"} value={specialist} />
                            <ExpertData name={"rating"} value={
                                <div>
                                    <Rating name="size-small" defaultValue={4.5} size="medium" />
                                </div>
                            } />
                            <ExpertData name={"current position"} value={"British Museum"} />
                            <ExpertData name={"speciality"} value={
                                <ul>
                                    <li>Ancient Egypt</li>
                                    <li>Assyriology</li>
                                </ul>
                            } />
                            <ExpertData name={"email"} value={
                                <Link href={"mailto:julie.marchand@bmuseum.uk"}  style={{color: 'red'}}>julie.marchand@bmuseum.uk</Link>
                            } />
                            <ExpertData name={"tel"} value={"..."} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} color={"secondary"}>
                        {t("ContactThisExpert")}
                    </Button>
                </DialogActions>
            </Dialog>;
        })}
    </Box>;
}

function ExpertData({name, value}) {
    return <Grid container spacing={1} style={{marginBottom: 4}}>
        <Grid item xs={5}>
            <strong>{name}</strong>
        </Grid>
        <Grid item xs={7}>
            {value}
        </Grid>
    </Grid>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    image: {
        width: '100%',
        height: 'auto'

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));
