import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import {useDispatch} from "react-redux";
import {imageAdded, imageUpdated, imagesFetched} from "./imagesSlice";
import client from '../../api/client';

const objectsAdapter = createEntityAdapter();
const initialState = objectsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all objects
export const fetchObjects = createAsyncThunk('objects/fetchObjects', async () => {
    const response = await client.get('/getObjects');
    return response.objects;
});

// fetch Collection objects
export const fetchCollectionObjects = createAsyncThunk(
    'objects/fetchCollectionObjects',
    async (collectionId, thunkAPI) => {
        const response = await client.get('/collection/'+collectionId+'/getObjects');
        const objects = [];
        response.objects.map(object => {
            thunkAPI.dispatch(imagesFetched(object.images));
            delete object.images;
            objects.push(object);
        });
        return objects;
    });

// add Object
export const createObject = createAsyncThunk(
    'objects/createObject',
    async (parameters, thunkAPI) => {
        const body = {
            collections: [],
            images: [],
            title: {default : "untitled"},
            description: {default : "untitled"},
        };
        if (parameters.categoryId)body.category = parameters.categoryId;
        if (parameters.collectionId)body.collections.push(parameters.collectionId);
        if (parameters.imageIds)body.images = parameters.imageIds;
        if (parameters.imageId){
            // Adding version images
            const images = thunkAPI.getState().images
            images.ids.map((id,i) => {
                if (images.entities[id].meta.versionOf === parameters.imageId) {
                    body.images.push(id);
                }
            })
            body.images.push(parameters.imageId);
        }
        const response = await client.post('/createObject', body);
        const object = response.collection; // TODO: API should return "object" instead
        thunkAPI.dispatch(imagesFetched(object.images));
        delete object.images;
        return object;
    }
);

export const updateObject = createAsyncThunk(
    'object/updateObject',
    async (parameters) => {
        const response = await client.post('/updateObject/'+parameters.objectId, parameters.body);
        return response.object;
    });

export const addToCollection = createAsyncThunk(
    'object/addToCollection',
    async (parameters) => {
        const response = await client.post('/collection/addObject', parameters.body);
        return response.object;
    });

export const deleteObject = createAsyncThunk(
    'object/deleteObject',
    async (parameters) => {
        const response = await client.get('/deleteObject/'+parameters.objectId);
        console.log(response);
        if (response)return {id: parameters.objectId};
    });

export const removeImage = createAsyncThunk(
    'object/removeImage',
    async (parameters, thunkAPI) => {
        const response = await client.delete('/object/removeImage', parameters.body);
        const object = response.object
        thunkAPI.dispatch(imagesFetched(object.images));
        delete object.images;
        return object;
    });

export const addImage = createAsyncThunk(
    'object/addImage',
    async (parameters, thunkAPI) => {
        const response = await client.post('/object/addImage', {
            image_hash: parameters.imageId,
            object_hash: parameters.objectId,
        });
        const object = response.object
        thunkAPI.dispatch(imagesFetched(object.images))
        delete object.images;
        return object;
    });

// THE REDUCERS
const objectsSlice = createSlice({
    name: 'objects',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchObjects.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchObjects.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched objects to the array
            objectsAdapter.upsertMany(state, action.payload);
        },
        [fetchObjects.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchCollectionObjects.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCollectionObjects.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            objectsAdapter.upsertMany(state, action.payload);
        },
        [fetchCollectionObjects.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [createObject.fulfilled]: objectsAdapter.addOne,
        [updateObject.fulfilled]: objectsAdapter.upsertOne,
        [addToCollection.fulfilled]: objectsAdapter.upsertOne,
        [removeImage.fulfilled]: objectsAdapter.upsertOne,
        [addImage.fulfilled]: objectsAdapter.upsertOne,
        [deleteObject.fulfilled]: (state, action) => {
            objectsAdapter.removeOne(state, action.payload.id);
        },
    },
});

export default objectsSlice.reducer

export const {
    selectAll: selectAllObjects,
    selectById: selectObjectById,
    selectIds: selectObjectIds,
} = objectsAdapter.getSelectors((state) => state.objects);

export const selectObjectsByCollection = createSelector(
    [selectAllObjects, (state, collectionId) => collectionId],
    (objects, collectionId) => objects.filter((object) => object.collections.includes(collectionId))
);


