import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';
import {selectAllCollections} from "./collectionsSlice";

const collectionTypeFieldsAdapter = createEntityAdapter();
const initialState = collectionTypeFieldsAdapter.getInitialState({
    status: 'idle',
    error: null,
    createStatus: 'idle',
    createError: null,
});

// fetch all collectionTypeFields
export const fetchCollectionTypeFields = createAsyncThunk(
    'collectionTypeFields/fetchCollectionTypeFields',
    async (parameters, thunkAPI) => {
        const response = await client.get('/collectionTypeFields/getAll');
        return response.collectionTypeFields;
    }
);

export const createCollectionTypeField = createAsyncThunk(
    'collectionTypeFields/createCollectionTypeField',
    async (parameters, thunkAPI) => {
        const response = await client.post('/collectionTypeFields/create', parameters);
        return response.collectionTypeField;
    }
);

// THE REDUCERS
const collectionTypeFieldsSlice = createSlice({
    name: 'collectionTypeFields',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchCollectionTypeFields.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCollectionTypeFields.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            collectionTypeFieldsAdapter.upsertMany(state, action.payload);
        },
        [fetchCollectionTypeFields.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [createCollectionTypeField.pending]: (state, action) => {
            state.createStatus = 'loading';
        },
        [createCollectionTypeField.fulfilled]: (state, action) => {
            state.createStatus = 'success';
            collectionTypeFieldsAdapter.addOne(state, action.payload);
        },
        [createCollectionTypeField.rejected]: (state, action) => {
            state.createStatus = 'failed';
            state.error = action.payload;
        }
    },
});

// export const {  } = collectionTypeFieldsSlice.actions;

export default collectionTypeFieldsSlice.reducer

export const {
    selectAll: selectAllCollectionTypeFields,
    selectById: selectCollectionTypeFieldById,
    selectIds: selectCollectionTypeFieldIds,
} = collectionTypeFieldsAdapter.getSelectors((state) => state.collectionTypeFields);

export const selectCollectionFieldsOfType = createSelector(
    [selectAllCollectionTypeFields, (state, collectionTypeId) => collectionTypeId],
    (collectionTypeFields, collectionTypeId) => collectionTypeFields.filter((collectionTypeField) => collectionTypeField.collection_type_id === collectionTypeId)
);