import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectImagesVersions, selectImageById} from "../../store/reducers/imagesSlice";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Paper from '@material-ui/core/Paper';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogActions from "@material-ui/core/DialogActions";
// import Button from "@material-ui/core/Button";

export default function CollectionImage ({imageId, imageSize, isEditing}) {
    const classes = useStyles();
    const image = useSelector(state => selectImageById(state, imageId));
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const versionImages = useSelector(state => selectImagesVersions(state, imageId));
    const handleModalOpen = () => setModal(true);
    const handleModalClose = () => setModal(false);
    const getMappedSize = () => {
        return imageSize * 25 + 50;
    };
    const content = <img
        alt=""
        src={image.binary ? image.binary : image.urls.medium}
        draggable={false}
        className={classes.media}
        style={{width: getMappedSize(), height: getMappedSize()}}
        onDoubleClick={handleModalOpen}
    />;
    return (
        <Paper className={classes.image} variant="outline">
            <div>
                {
                    // TODO: simplify this
                    versionImages.length
                        ?
                        <Badge color="secondary" badgeContent={versionImages.length} className={classes.badge}>
                            {content}
                        </Badge>
                        :
                        <Badge color="secondary" badgeContent={0} showZero={false}>
                            {content}
                        </Badge>
                }
            </div>
            <Dialog
                open={modal}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth="sm"
            >
                <DialogTitle>
                    Image {imageId}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <img alt="" src={image.urls.big} draggable={false} className={classes.media} />
                        </Grid>
                        {versionImages.map((version, i)=>(
                            <Grid item sm={6}>
                                <img alt="" src={version.urls.big} draggable={false} className={classes.media} />
                            </Grid>
                        ))}
                    </Grid>
                    <DialogContentText>

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Paper>
    );
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    badge: {

    },
    image: {
        borderStyle: 'hash',
        borderColor: theme.palette.primary.contrastText,
        borderWidth: 1,
        padding: theme.spacing(1),
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    }
}));