
export function formatDataAsCols(data, columns) {
    const formattedData = [];
    data.map((item, index) => {
        let row = {};
        columns.map(column => {
            row[column.field] = item[column.field];
        });
        formattedData.push(row);
    });
    return formattedData;
}