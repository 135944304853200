import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import {useDispatch} from "react-redux";
import {imageAdded, imageUpdated, imagesFetched} from "./imagesSlice";
import client from '../../api/client';
import investigationsSlice from "./investigationsSlice";

const tasksAdapter = createEntityAdapter();
const initialState = tasksAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all tasks
export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async () => {
    const response = await client.get('/getTasks');
    return response.tasks;
});

// add Task
export const createTask = createAsyncThunk(
    'tasks/createTask',
    // DO THAT
    async (parameters, thunkAPI) => {

    }
);

// THE REDUCERS
const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        tasksFetched(state, action) {
            tasksAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: {
        [fetchTasks.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchTasks.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched tasks to the array
            tasksAdapter.upsertMany(state, action.payload);
        },
        [fetchTasks.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [createTask.fulfilled]: tasksAdapter.addOne,
    },
});

export const { tasksFetched } = tasksSlice.actions;

export default tasksSlice.reducer

export const {
    selectAll: selectAllTasks,
    selectById: selectTaskById,
    selectIds: selectTaskIds,
} = tasksAdapter.getSelectors((state) => state.tasks);

export const selectTasksByInvestigation = createSelector(
    [selectAllTasks, (state, investigationId) => investigationId],
    (tasks, investigationId) => tasks.filter((task) => task.investigation_id === investigationId)
);