import React, {useState, useEffect, createRef, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';


import {
    addNewSurvey
} from "../../store/reducers/surveysSlice";

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import client_ai from '../../api/client_ai';


const SurveyCreate = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fileInput = createRef();
    const {t, i18n} = useTranslation();
    const form = useRef(null)

    const [survey_name, setSurveyName] = useState(null);
    const handleSurveyName = (event) => setSurveyName(event.target.value);


    const ecommerceSites = [
      { title: 'Ebay', value:'ebay'},
      { title: 'Le bon coin', value:'le_bon_coin'},
      { title: 'Catawiki', value:'catawiki'}
    ]
    const [targetSites, setTargetSites] = useState(null);
    const handleTargetSites = (event,values) => setTargetSites(values);

    FileReader.prototype.setFile = function(file) {
        this.file = file;
        return this;
    };
    const [image, setImage] = useState(null);
    const handleGetImage = () => {
        if (fileInput.current.files.length === 1) {
            const file = fileInput.current.files[0];
            const reader = new FileReader().setFile(file);
            reader.onload = function(event) {
                setImage({
                    fullImage: 'data:'+this.file.type+';base64,'+window.btoa(this.result),
                    name: this.file.name
                });
            };
            reader.readAsBinaryString(file);
            // dispatch(searchInit());
        }
    };
    const handleOpenFile = () => {
        fileInput.current.click();
    }
    const removeBackground = () => {
      var data = new FormData(form.current)
      client_ai.post("/remove_background", image).then(
        (response)=>{
          setImage({
              url: response.url,
              name: image.name
          });
        }
      )
    }
    const getImage = () => {
      if ('url' in image) return image.url;
      if ('fullImage' in image) return image.fullImage;
    }

    const createSurvey = () => {
      if (survey_name && image && targetSites) {
          dispatch(addNewSurvey({
              body: {
                  name: survey_name,
                  search: {"image": image, "terms": inputList},
                  target: targetSites
              }
          }));
      }
    }
    const [inputList, setInputList] = useState([""]);

    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index] = value;
      console.log(list)
      setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
      setInputList([...inputList, ""]);
    };


    return <Card>
        <CardHeader title={t('CreateNewSurvey')} />
        <CardContent>
            {/* TODO: form here */}
            <form ref={form}>
            <Grid container spacing={2} justify={"center"}>
                <Grid md={4} sm={6} xs={12} item>
                    {
                        image
                            ?   <img src={getImage()} style={{ width: '100%', height: 'auto'}}   />
                            :   <Box display="flex" alignItems={"stretch"}  style={{cursor: 'pointer', border: '2px dashed #ccc', height: 200, backgroundColor: "#e0e0e0"}} onClick={handleOpenFile}>
                              <AddAPhotoIcon style={{ margin:' 0 auto', top: '62px', position: 'relative', 'font-size': '65px', color: '#999'}} />
                            </Box>
                    }
                </Grid>
              </Grid>
              <Grid container spacing={2} justify={"center"}>
                <Grid md={6} sm={6} xs={12} style={{'text-align': 'center'}}>
                    {!image
                        ?   <Button style={{ display: 'none'}}variant="contained" color="secondary" type="file" component='label'>
                                <input ref={fileInput} name="file" onChange={handleGetImage} type="file" style={{display:"none"}} />
                                {t('ChoosePicture')}
                            </Button>
                        :   <><Button variant="contained" color="default" startIcon={<AddAPhotoIcon />} type="file" component='label'>
                                <input ref={fileInput} name="file" onChange={handleGetImage} type="file" style={{display:"none"}} />
                                {t('ChangePicture')}
                            </Button>
                            <Button variant="contained" color="default" startIcon={<AddAPhotoIcon />} onClick={removeBackground} component='label'>
                              {t('RemoveBackground')}
                            </Button></>
                    }
                </Grid>
              </Grid>
              <Grid container spacing={2} justify={"center"}>
                <Grid md={4} sm={6} xs={12} item>
                  <h3>{t('SurveySettings')}</h3>
                    <TextField
                        id="survey_name"
                        label={t('SurveyName')}
                        type="text"
                        variant="outlined"
                        className={classes.input}
                        value={ survey_name || "" }
                        onChange={handleSurveyName}
                    />
                    <Divider variant="middle" />
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      options={ecommerceSites}
                      getOptionLabel={(option) => option.title}
                      onChange={handleTargetSites}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Sites à surveiller"
                          placeholder="Sélectionnez un site"
                        />
                      )}
                    />
                    <h3>{t('SearchTerms')}</h3>
                    <div>
                      {inputList.map((x, i) => {
                        return (
                          <div className="box">
                            <TextField
                              placeholder={t('EnterSearchTerm')}
                              value={x}
                              onChange={e => handleInputChange(e, i)}
                               style={{width:'80%'}}
                            />
                            <span className="btn-box">
                              {inputList.length !== 1 && <span
                                style={{cursor: 'pointer'}}
                                onClick={() => handleRemoveClick(i)}><HighlightOff /></span>}
                              {inputList.length - 1 === i && <span style={{cursor: 'pointer', 'margin-left': '15px'}} onClick={handleAddClick}><Add /></span>}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                </Grid>
            </Grid>
            </form>
        </CardContent>
        <CardActions style={{justifyContent: "center"}}>
            <Button onClick={createSurvey} color="secondary" variant="contained">
                {t('CreateSurvey')}
            </Button>
        </CardActions>
   </Card>;
};

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    input: {
        marginTop: ".5rem",
        width: '100%'
    }
}));

export default SurveyCreate;
