import React, {useEffect} from 'react';
import {Container, Typography, Icon, createMuiTheme, ThemeProvider} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import SimpleTable, {SimpleGrid} from "../Misc/Table";
import {
    selectAllSurveys,
    fetchSurveys,
    selectSurveysByCriminalCase,
    selectSurveysByInvestigation
} from "../../store/reducers/surveysSlice";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";
import {DataGrid} from "@material-ui/data-grid";
import Chip from "@material-ui/core/Chip";

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import WarningIcon from '@material-ui/icons/Warning';

const Surveys = ({investigationId, criminalCaseId, simpleTable, height, filter, title}) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.surveys.status);

    const surveysCC = useSelector(state => selectSurveysByCriminalCase(state, criminalCaseId ? criminalCaseId : 0));
    const surveysIV = useSelector(state => selectSurveysByInvestigation(state, investigationId ? investigationId : 0));
    const surveysAll = useSelector(selectAllSurveys);

    const surveys = criminalCaseId ? surveysCC : (investigationId ? surveysIV : surveysAll);

    const filtered = filter
        ? surveys.filter((survey) => survey.notif >= 0)
        : surveys;

    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchSurveys(null));
        }
    }, [status, dispatch]);

    const getViewCell = (params) => {
        return <Button variant="contained" color="default" size="small" to={'/surveys/'+params.row.id} component={NavLink}>
            {t('See')}
        </Button>
    };

    const getNotifCell = (params) => {
        return <>
            {params.row.notif > 0 ?
                <Chip size="small" icon={<NotificationsActiveIcon />} label={params.row.notif} color={"primary"} /> : null}
            {params.row.alert > 0 ?
                <Chip size="small" icon={<WarningIcon />} label={params.row.alert} color={"secondary"} /> : null}
            </>
    };
    const getImageCell = (params) => {
        return <>
            { 'image' in params.row.search ?
                <img src={params.row.search.image.url} style={{ height: '40px'}}   /> : null}
            </>
    };
    const getTargetCell = (params) => {
        return <>
            { params.row.target ?
              params.row.target.map((value) => {return <span key={value.title} style={{ marginRight: '10px'}}>{value['title']} </span>})  : null}
            </>
    };


    const getTermsCell = (params) => {
        return <>
            { params.row && 'terms' in params.row.search ?
              params.row.search.terms.map((value) => {return <div key={value} style={{ marginRight: '10px'}}>{value} </div>})
              : null}
            </>
    };



    const columns = [
        {field:"name",          headerName:"Nom",           attr: {xs: 1}, flex:1},
        {field:"image",         headerName:"Image",         attr: {xs: 1}, flex:1, renderCell: getImageCell},
        {field:"terms",         headerName:"Mots clefs",    attr: {xs: 1}, flex:1, renderCell: getTermsCell},
        {field:"target",        headerName:"Cibles",        attr: {xs: 2}, flex:2, renderCell: getTargetCell},
        {field:"alerte",        headerName:"Alerte",        attr: {xs: 1}, flex:1, renderCell: getNotifCell},
        {field:"id",        headerName:"Action",        attr: {xs: 1}, flex:1, renderCell: getViewCell}
    ];
    return <Card>
        <CardHeader title={title ? title : t('Surveys')} />
        <CardContent>
            {
                simpleTable
                ?   <SimpleGrid columns={columns} rows={filtered} pageSize={8} spacing={0} />
                :   <div style={{height:window.innerHeight - 300}}>
                        <DataGrid columns={columns} rows={filtered} pageSize={8} />
                    </div>
            }
        </CardContent>
        <CardActions>
            <Button title={t('CreateNewSurvey')} variant="contained" color="default" to={"/surveys/new"} component={NavLink}>
                {t('CreateNewSurvey')}
            </Button>
        </CardActions>
    </Card>
};

export default Surveys;

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    card: {

    },
    create: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        height: 100
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));
