export function getCriminalCasesFields() {
    return [
        {field:"num_ref",               group: "info",      order:  2,  label: "Date de mise à jour"},  // missing
        {field:"updated_date",          group: "info",      order:  3,  label: "Origine",  thesaurus:"caseOrigin"},
        {field:"origin",                group: "info",      order:  1,  label: "Numéro affaire"},
        {field:"references",            group: "info",      order:  4,  label: "Références"},
        {field:"opening_date",          group: "info",      order:  5,  label: "Date d'information"},
        {field:"is_solved",             group: "status",    order:  6,  label: "Faits résolus"},
        {field:"resolution_service",    group: "info",      order:  7,  label: "Service de résolution",  thesaurus:"resolutionService"}, // missing
        {field:"date",                  group: "action",    order:  8,  label: "Date"}, // missing
        {field:"action_date",           group: "action",    order:  9,  label: "Date des faits"}, // missing
        {field:"action_place_type",     group: "action",    order: 10,  label: "Nature du lieu", thesaurus: "caseLocationType"}, // pas date !!!
        {field:"action_address",        group: "action",    order: 11,  label: "Adresse des faits"},
        {field:"action_city",           group: "action",    order: 12,  label: "Commune des faits"},
        {field:"action_country",        group: "action",    order: 13,  label: "Pays des faits"},
        {field:"action_department",     group: "action",    order: 14,  label: "Département des faits"},
        {field:"action_type",           group: "action",    order: 15,  label: "Infraction",  thesaurus:"infraction"}, // missing
        {field:"summary",               group: "summary",   order: 16,  label: "Résumé succint"},
        {field:"victim_name",           group: "victim",    order: 17,  label: "Nom de la victime"},
        {field:"victim_phone",          group: "victim",    order: 18,  label: "Téléphone de la Victime"},
        {field:"victim_adress",         group: "victim",    order: 19,  label: "Adresse de la victime"},
        {field:"victim_city",           group: "victim",    order: 20,  label: "Commune de la victime"}, // missing
        {field:"victim_department",     group: "victim",    order: 21,  label: "Departement de la victime"},
        {field:"victim_country",        group: "victim",    order: 22,  label: "Pays de la victime"},
        {field:"plaintiff_name",        group: "legal",     order: 23,  label: "Nom du plaignant"},
        {field:"plaintiff_phone",       group: "legal",     order: 24,  label: "Téléphone du plaignant"},
        {field:"taken",                 group: "legal",     order: 25,  label: "Corps Saisi",  thesaurus:"caseTaken"}, // missing
        {field:"service",               group: "admin",     order: 26,  label: "Service"}, // missing
        {field:"OIPC_circ",             group: "admin",     order: 27,  label: "Circulaire OIPC"},
        {field:"national_circ",         group: "admin",     order: 28,  label: "Circulaire Nationale"},
        {field:"OIPC_control",          group: "admin",     order: 29,  label: "Contrôle OIPC"},
        {field:"OIPC_cess",             group: "admin",     order: 30,  label: "Cessation OIPC"},
        {field:"cess",                  group: "admin",     order: 31,  label: "Cessation"},
        {field:"note",                  group: "admin",     order: 32,  label: "Note"}, // missing
   ];
}