import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/demo';

const queriesAdapter = createEntityAdapter();
const initialState = queriesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all queries
export const fetchQueries = createAsyncThunk('queries/fetchQueries', async () => {
    const response = await client.get('/demo/queries');
    return response.queries;
});

// THE REDUCERS
const queriesSlice = createSlice({
    name: 'queries',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchQueries.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchQueries.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched queries to the array
            queriesAdapter.upsertMany(state, action.payload);
        },
        [fetchQueries.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

// export const {  } = queriesSlice.actions;

export default queriesSlice.reducer

export const {
    selectAll: selectAllQueries,
    selectById: selectQueryById,
    selectIds: selectQueryIds,
} = queriesAdapter.getSelectors((state) => state.queries);

export const selectQueriesByInvestigation = createSelector(
    [selectAllQueries, (state, investigationId) => investigationId],
    (queries, investigationId) => queries.filter((query) => (
        query.investigations.indexOf(investigationId) >= -1
    ))
);
