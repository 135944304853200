import {
    createSlice
} from '@reduxjs/toolkit';

const initialState = {
    showDrawer: false,
    displayMode: false,
    currentNode: false
};

const selectorSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        showDrawer(state) {
            state.showDrawer = true
        },
        hideDrawer(state) {
            state.showDrawer = false
        },

    }
});

export default selectorSlice.reducer
export const { showDrawer, hideDrawer } = selectorSlice.actions;
export const getDrawerVisible = (state) => state.selector.showDrawer;