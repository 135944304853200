import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';

const usersAdapter = createEntityAdapter();
const initialState = usersAdapter.getInitialState({
    status: 'idle',
    error: null,
});

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await client.get('/users');
    return response.users;
});

export const addNewUser = createAsyncThunk(
    'users/addNewUser',
    async (initialUsers) => {
        const response = await client.post('/users', { put: initialUsers });
        return response.user;
    }
);

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        userUpdated(state, action) {
            const { id, title, content } = action.payload;
            const existingUser = state.entities[id];
            if (existingUser) {
                existingUser.title = title;
                existingUser.content = content;
            }
        },
    },
    extraReducers: {
        // fetchUsers
        [fetchUsers.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchUsers.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched users to the array
            usersAdapter.upsertMany(state, action.payload);
        },
        [fetchUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [addNewUser.fulfilled]: usersAdapter.addOne,
    },
});

export const { userAdded, userUpdated } = usersSlice.actions;

export default usersSlice.reducer

export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
} = usersAdapter.getSelectors((state) => state.users);

export const selectUsersByUser = createSelector(
    [selectAllUsers, (state, userId) => userId],
    (users, userId) => users.filter((user) => user.user === userId)
);

