import React, {useState, useEffect} from "react";
import clsx from "clsx";
import {ClickAwayListener, Input, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const KEY_ENTER = 13;
const KEY_ESC = 27;

export default function EditableText({initValue, field, type, textClasses, inputClasses, onChange, variant}) {
    const [editing, setEditing] = useState(false);
    const classes = useStyles();
    const [value, setValue] = useState(initValue);
    const handleEdit = () => setEditing(true);
    const handleUnEdit = () => {
        setEditing(false);
        onChange(value, field);
    }
    const handleChange = (event) => setValue(event.target.value);
    const handleKey = (event) => {
        if (event.keyCode === KEY_ENTER) {
            handleUnEdit();
        }
        else if (event.keyCode === KEY_ESC) {
            setValue(initValue);
            setEditing(false);
        }
    };

    return !editing
        ? <Typography onClick={handleEdit} classes={textClasses} variant={variant}>{initValue ? initValue : '(vide)'}</Typography>
        : <ClickAwayListener onClickAway={handleUnEdit}>
            <Input
                onKeyUp={handleKey}
                onChange={handleChange}
                value={value}
                type={type && type.name === "Date" ? "date" : null}
                classes={{
                    root: clsx(inputClasses, classes.root),
                    formControl: classes.base,
                    input: classes.input
                }}
            />
        </ClickAwayListener>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    base: {
        borderWidth: 0,
        padding:0
    },
    input: {
        padding: 0
    }
}));