import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/demo';
import {selectAllFieldTypes} from "./fieldTypesSlice";
import {selectAllImages} from "./imagesSlice";
import {selectAllTasks} from "./tasksSlice";

const datasourcesAdapter = createEntityAdapter();
const initialState = datasourcesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all datasources
export const fetchDatasources = createAsyncThunk(
    'datasources/fetchDatasources',
    async () => {
        const response = await client.get('/demo/databases');
        return response.databases;
    }
);

// THE REDUCERS
const datasourcesSlice = createSlice({
    name: 'datasources',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchDatasources.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchDatasources.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched datasources to the array
            datasourcesAdapter.upsertMany(state, action.payload);
        },
        [fetchDatasources.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        }
    },
});

// export const {  } = datasourcesSlice.actions;

export default datasourcesSlice.reducer

export const {
    selectAll: selectAllDatasources,
    selectById: selectDatasourceById,
    selectIds: selectDatasourceIds,
} = datasourcesAdapter.getSelectors((state) => state.datasources);

export const selectAgreedDatasources = createSelector(
    [selectAllDatasources],
    (datasources) => datasources.filter((datasources) => datasources.show === "1")
);