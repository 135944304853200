import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation}     from 'react-i18next';

import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";

import { NavLink, useParams} from "react-router-dom";

import { DataGrid } from '@material-ui/data-grid';

import Typography from "@material-ui/core/Typography";
import MaterialTable from 'material-table';

import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";

import {selectCriminalCaseById} from "../../store/reducers/criminalCasesSlice";
import {
    selectInvestigationById,
    fetchInvestigationById,
    fetchInvestigationUpdate,
    createInvestigation
} from "../../store/reducers/investigationsSlice";
import {selectTasksByInvestigation} from "../../store/reducers/tasksSlice";
import {selectCriminalCasesByInvestigation} from "../../store/reducers/criminalCasesSlice";
import {selectAllCriminalCase} from "../../store/reducers/criminalCasesSlice";
import {selectCollectionsByInvestigation} from "../../store/reducers/collectionsSlice";

import {formatDataAsCols} from "../../services/dataGridTools";
import SimpleTable, {SimpleGrid} from "../Misc/Table";
import Surveys from "../Survey/Surveys";
import EditableText from "../Misc/EditableText";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Link, TextField} from "@material-ui/core";
import Collection from "../Collection/Collection.jsx";
import {CollectionCreate} from "../Collection/CollectionList.jsx";

const Investigation = () => {
    const { investigationId } = useParams();
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const investigation = useSelector(state => selectInvestigationById(state, investigationId));
    const tasks = useSelector(state => selectTasksByInvestigation(state, investigationId));
    const criminalCases = useSelector(state => selectCriminalCasesByInvestigation(state, investigationId));
    const collections = useSelector(state => selectCollectionsByInvestigation(state, investigationId));

    const [editing, setEditing] = useState(false);
    const handleEditing = (event, newValue) => setEditing(newValue);

    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(fetchInvestigationUpdate({
            investigationId: investigationId,
            body: body
        }))
    }

    const [openLink, setOpenLink] = useState(false);
    const handleOpenLink = () => setOpenLink(true);
    const handleCloseLink = () => setOpenLink(false);

    // const [openTask, setOpenTask] = useState(false);
    // const handleOpenTask = () => setOpenTask(true);
    // const handleCloseTask = () => setOpenTask(false);

    const [openCreateTask, setOpenCreateTask] = useState(false);
    const handleOpenCreateTask = () => setOpenCreateTask(true);
    const handleCloseCreateTask = () => setOpenCreateTask(false);

    const handleLinkToCases = () => {

    }

    const [openCreateCollection, setOpenCreateCollection] = useState(false);
    const handleOpenCreateCollection = () => setOpenCreateCollection(true);
    const handleCloseCreateCollection = () => setOpenCreateCollection(false);

    useEffect(()=>{
        dispatch(fetchInvestigationById(investigationId));
    }, [investigationId, dispatch]);

    if (!investigation)return <Container disableGutters={true}>Loading</Container>;

    const taskColumns = [
        {field: "name", label: "Task", flex:2},
        {field: "description", label: "Description", flex:3},
        {field: "due_date", label: "Date", flex: 1}
    ];
    const taskData = formatDataAsCols(tasks, taskColumns);
    return <>
        <Card elevation={5} className={classes.investigation}>
            <CardHeader
                title={
                    editing
                        ? <EditableText
                            initValue={investigation.name}
                            onChange={handleChange}
                            field='name'
                            variant='h5'
                            inputClasses={classes.h5input}
                        />
                        : investigation.name
                }
                subheader={
                        editing
                            ? <EditableText
                                initValue={investigation.description}
                                onChange={handleChange}
                                field='description'
                                inputClasses={classes.descriptionInput}
                            />
                            : investigation.description
                }
                action={
                    <Switch value={editing} onChange={handleEditing} />
                }
            />
            <CardContent>
                {criminalCases.length
                    ? <>
                        <Typography>{t('CasesLinkedToInvestigation')}</Typography>
                        {criminalCases.length === 0
                            ? <CriminalCaseSummary criminalCase={criminalCases[0]} />
                            : <ul>
                                {criminalCases.map((investigationCase, i) => {
                                    return <li>
                                        <CriminalCaseSummary criminalCase={investigationCase} />
                                    </li>
                                })}
                            </ul>
                        }
                    </>
                    : <Typography>{t('NoCasesLinkedToInvestigation')}</Typography>}
                    <Link href="#" color={"secondary"} onClick={handleLinkToCases}>{t("AttachCaseToInvestigation")}</Link>
            </CardContent>
            <CardContent>
                <Fade in={true} timeout={1000}>
                    <Card elevation={3}>
                        <CardHeader title={t("Tasks")} />
                        <CardContent>
                            {
                                ! tasks.length
                                    ?   <Typography>{t('NoTasksUpcoming')}</Typography>
                                    :   <SimpleGrid
                                        columns={[
                                            {field: "due_date",     label: "Date",          attr: {xs:2}},
                                            {field: "name",         label: "Task",          attr: {xs:4}},
                                            {field: "description",  label: "Description",   attr: {xs:6}}
                                        ]}
                                        rows={tasks}
                                        spacing={1}
                                        headerClass={classes.headerCell}
                                    />
                            }
                        </CardContent>
                        <CardActions>
                            <Button color="primary" variant="contained" onClick={handleOpenCreateTask}>
                                {t('CreateTask')}
                            </Button>
                            <InvestigationTaskCreate open={openCreateTask} onClose={handleCloseCreateTask} />
                        </CardActions>
                    </Card>
                </Fade>
            </CardContent>
            <CardContent>
                <Surveys investigationId={investigationId} simpleTable={true} />
            </CardContent>
                {collections.map(collection => {
                    return  <CardContent>
                        <Collection collectionId={collection.id} />
                    </CardContent>
                })}
            <CardActions style={{justifyContent: "flex-end"}}>
                <Button variant={"contained"} color={"secondary"} onClick={handleOpenCreateCollection}>
                    {t('AddCollectionInInvestigation')}
                </Button>
            </CardActions>
            <CollectionCreate open={openCreateCollection} onClose={handleCloseCreateCollection} investigationId={investigationId} />
        </Card>
    </>;
};


function CriminalCaseSummary({criminalCase}) {
    return <Link component={NavLink} color={"secondary"} to={`/cases/${criminalCase.id}`}>{criminalCase.summary}</Link>;
}


function InvestigationTaskCreate({open, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const [date, setDate] = useState(null);
    const handleDate = (event) => setDate(event.target.value);

    const [name, setName] = useState(null);
    const handleName = (event) => setName(event.target.value);

    const [description, setDescription] = useState(null);
    const handleDescription = (event) => setDescription(event.target.value);

    const handleCreate = () => {
        if (name && description) {
            dispatch(createInvestigation({
                body: {
                    name: name,
                    description: description,
                }
            }));
            onClose();
        }
    }

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
        <DialogTitle
            title={t('CreateInvestigation')}
        />
        <DialogContent>
            <FormGroup>
                <TextField variant="standard" value={date} onChange={handleDate} placeholder="Date" type={"date"} />
            </FormGroup>
            <FormGroup>
                <TextField variant="standard" value={name} onChange={handleName} placeholder="Name" />
            </FormGroup>
            <FormGroup>
                <TextField variant="standard" value={description} onChange={handleDescription} placeholder="Description" />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" size="medium" color="secondary" onClick={handleCreate}>
                {t('CreateNewTask')}
            </Button>
        </DialogActions>
    </Dialog>;
}


function DataDisplay({label, value}) {
    const classes = useStyles();
    return <Grid item container className={classes.dataRow}>
        <Grid xs={4} className={classes.label}>{label}</Grid>
        <Grid xs={8} className={classes.value}>{value}</Grid>
    </Grid>;
}

// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    investigation: {
        backgroundColor: 'rgba(255,255,255,0.8)'
    },
    dataRow: {
        borderTop: 'Apx solid #e0e0e0'
    },
    headerCell: {
        fontWeight: "bold"
    },
    h5input: {
        fontSize: theme.typography.h5.fontSize,
    },
    descriptionInput: {
        color: theme.palette.secondary
    },
    label: {
        color: '#666'
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));

export default Investigation;