import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";
import {selectTreeThesaurusEntities} from "../../store/reducers/treeThesaurusSlice";
import tdb from "../../services/TranslateDB";
import EditableThesaurus from "../Misc/EditableThesaurus";
import Typography from "@material-ui/core/Typography";
import React from "react";
import EditableText from "../Misc/EditableText";
import EditableBoolean from "../Misc/EditableBoolean";

// just display
export function ObjectData({name, value}) {
    return <Grid container spacing={1} style={{marginBottom: 4}}>
        <Grid item xs={4}>
            <strong>{name}</strong>
        </Grid>
        <Grid item xs={8}>
            {value}
        </Grid>
    </Grid>
}

// all fields exect thesaurus
export function ObjectField({field, values, editing, type, update}) {
    const fieldValue = values && values[field.name] ? values[field.name] : null;
    if (!editing && (fieldValue === false || fieldValue === null))return null;

    let editable;
    if (type && type.name === "Boolean") {
        editable = <EditableBoolean field={field.name} initValue={fieldValue} onChange={update} type={type} />;
    }
    else {
        editable = <EditableText field={field.name} initValue={fieldValue} onChange={update} type={type} />;
    }

    return <Grid container spacing={1}>
        <Grid item xs={4}>
            <strong>{field.name} {field.tree_field_type_id}</strong>
        </Grid>
        <Grid item xs={8}>
            { editing ? editable : fieldValue}
        </Grid>
    </Grid>;
}

// thesaurus field
export function ObjectThesaurus({field, values, editing, update}) {

    const fieldValue = values ? values[field.name] : null;
    const thesaurusEntities = useSelector(selectTreeThesaurusEntities);

    if (!editing && !fieldValue)return null;

    const getId = (value) => {
        if (value && typeof value === "object" && 'id' in value)return value.id
        else if (typeof value == "number")return value;
        else return null;
    }

    const getLabel = (value) => {
        if (typeof value === "string")return value;
        const id = getId(value)
        if (id) {
            const thesaurusEntity = thesaurusEntities[id];
            if (thesaurusEntity) {
                return tdb(thesaurusEntity.title, thesaurusEntity.name);
            }
        }
        if (value && typeof value == "object" && 'value' in value)return value.value
        return "n/c";
    }

    let label, initValue;
    if (Array.isArray(fieldValue)) {
        label = fieldValue.map(value => {
            return getLabel(value);
        }).join(", ")
        initValue = fieldValue.map(value => {
            return getId(value);
        })
    }
    else {
        label = getLabel(fieldValue);
        initValue = getId(fieldValue);
    }

    const handleChange = (value) => {
        // console.log(field, value);
        if ('name' in field) {
            if (!value)update(null, field.name);
            else if ('id' in value)update(value.id, field.name);
        }
    }

    return <Grid container spacing={1}>
        <Grid item xs={4}>
            <strong>{field.name}</strong>
        </Grid>
        <Grid item xs={8}>
            {
                editing
                    ?   <EditableThesaurus
                        multiple={Array.isArray(fieldValue)}
                        initValue={initValue}
                        label={label}
                        onChange={handleChange}
                        field={"category"}
                        rootId={field.tree_thesaurus_id}
                    />
                    :   <Typography>{label}</Typography>
            }
        </Grid>
    </Grid>;
}