import React, {useRef} from "react";
import { useDrag, useDrop } from 'react-dnd';
import {useDispatch, useSelector} from "react-redux";
import {selectImageById, fetchUpdateImage} from "../../../store/reducers/imagesSlice";
import * as colors from "./Borders";

export default  ({imageId, name, children}) => {
    //const image = useSelector(state => selectImageById(state, imageId));
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [{ isDragging }, connectDrag] = useDrag({
        item: { name, type: "image" },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const [{canDrop, isOver}, connectDrop] = useDrop({
        accept: "image",
        drop: (item, monitor) => {
            if (item.type === "image" && item.name != imageId) {
                dispatch(fetchUpdateImage({
                    imageId: item.name,
                    body: {
                        meta: {
                            versionOf: imageId
                        }
                    }
                }))
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop() && monitor.getItem().name !== imageId,
        }),
    });
    connectDrag(ref);
    connectDrop(ref);
    const opacity = isDragging ? 0.5 : 1;
    let isActive = isOver && canDrop;
    let border= '2px solid transparent';
    if (isActive) {
        border= colors.activeColor;
    }
    else if (canDrop) {
        border= colors.canDropColor;
    }
    return (
        <div
            ref={ref}
            key={imageId}
            style={{...style, opacity, border}}
        >
            {children}
            <span></span>
        </div>
    );
}

const style = {
    cursor: 'move',
    margin: '-2px',
    position: 'relative'
};