import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation}     from 'react-i18next';

import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import Switch from "@material-ui/core/Switch";

import CollectionImages from './CollectionImages.jsx';
import CollectionObjects from './CollectionObjects.jsx';
import CollectionObjectsDnD from "./DnD/CollectionObjectsDnD.jsx";
import CollectionsIcon from "@material-ui/icons/Collections";

import {fetchCollectionById, selectCollectionById, updateCollection} from "../../store/reducers/collectionsSlice";
import {fetchTreeCategories, selectAllTreeCategories} from "../../store/reducers/treeCategoriesSlice";

import {Box, Grid} from "@material-ui/core";
import {fetchTreeFields} from "../../store/reducers/treeFieldsSlice";
import {fetchFieldTypes, selectAllFieldTypes} from "../../store/reducers/fieldTypesSlice";
import {fetchTreeThesauruses} from "../../store/reducers/treeThesaurusSlice";
import EditableText from "../Misc/EditableText";
import tdb from "../../services/TranslateDB";
import Typography from "@material-ui/core/Typography";

import {ObjectData, ObjectField, ObjectThesaurus} from "./CollectionFields";
import {fetchCollectionTypes, selectCollectionTypeById} from "../../store/reducers/collectionTypesSlice";
import CardContent from "@material-ui/core/CardContent";
import {
    fetchCollectionTypeFields,
    selectAllCollectionTypeFields, selectCollectionFieldsOfType,
    selectCollectionTypeFieldIds
} from "../../store/reducers/collectionTypesFieldsSlice";

const Collection = ({collectionId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const categories = useSelector(selectAllTreeCategories);

    const collectionStatus = useSelector(state => state.collections.status);
    const thesaurusStatus = useSelector(state => state.treeThesauruses.status);
    const collectionTypeStatus = useSelector(state => state.collectionTypes.status);
    const collectionTypeFieldStatus = useSelector(state => state.collectionTypeFields.status);


    const [editing, setEditing] = useState(false);
    const handleEditing = (event, result) => setEditing(result);

    // first time, we need to load all collection in case we get directly on this page
    // TODO : think about do this from slice
    useEffect(() => {
        if (!collection) {
            dispatch(fetchCollectionById(collectionId));
        }
        if (!categories.length) {
            dispatch(fetchTreeCategories(null));
            dispatch(fetchTreeThesauruses(null));
            dispatch(fetchTreeFields(null));
            dispatch(fetchCollectionTypes(null));
            dispatch(fetchCollectionTypeFields(null));
            dispatch(fetchFieldTypes(null));
        }
    }, [collection, dispatch]);

    if (!collection) {
        return <div>Loading</div>;
    }

    const statuses = [thesaurusStatus, collectionTypeStatus, collectionTypeFieldStatus]
    if (statuses.find(status => status !== 'succeeded')) {
        return <div>
            Loading
        </div>;
    }

    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(updateCollection({
            collectionId: collectionId,
            body: body
        }))
    }

    const handleChangeField = (value, field) =>  {
        let body = {
            fields:{}
        };
        body.fields[field] = value;
        dispatch(updateCollection({
            collectionId: collectionId,
            body: body
        }))
    }

    return <Card elevation={3}>
        <CardHeader
            avatar={<CollectionsIcon fontSize="large"/>}
            title={ editing
                ? <EditableText
                    initValue={collection.name}
                    onChange={handleChange}
                    field='name'
                    variant='h5'
                    inputClasses={classes.h5input}
                />
                : <Typography variant="h5">{collection.name}</Typography>
            }
            action={<Box style={{flexDirection: 'row'}}>
                {t('Edit')}
                <Switch
                    value={editing}
                    name={"edit"}
                    onChange={handleEditing}
                />
            </Box>}
        >
        </CardHeader>
        <CollectionFields collectionId={collectionId} editing={editing} handleFieldUpdate={handleChangeField} />
        {(editing)
            ?   <CollectionImages  collectionId={collectionId} editing={editing} />
            :   null}
        {editing
            ?   <CollectionObjectsDnD collectionId={collectionId}>
                    <CollectionObjects collectionId={collectionId} editing={editing} />
                </CollectionObjectsDnD>
            :   <CollectionObjects collectionId={collectionId} editing={editing} />}
        <CardActions>
            <Button variant={"contained"}>
                {t("CreateQueryFromCollection")}
            </Button>
        </CardActions>
    </Card>;
};

export default Collection;

function CollectionFields({collectionId, editing, handleFieldUpdate}) {
    const {t} = useTranslation();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const collectionType = useSelector(state => selectCollectionTypeById(state, collection.collection_type));
    const collectionTypeFields = useSelector((state) => selectCollectionFieldsOfType(state, collectionType.id));
    const types = useSelector(selectAllFieldTypes);
    return <CardContent>
        <Grid container spacing={1}>
            <Grid lg={6}>
                {collectionTypeFields.map((field, i) => {
                    const type = types.find(type => type.id === field.tree_field_type_id);
                    return (type && type.is_thesaurus)
                        ?   <ObjectThesaurus field={field} values={collection.fields} editing={editing} update={handleFieldUpdate} type={type} />
                        :   <ObjectField field={field} values={collection.fields} editing={editing} update={handleFieldUpdate} type={type} />
                })}
                {editing && <div>{t('Ajouter une valeur')}</div>}
            </Grid>
        </Grid>
    </CardContent>;
}



const lightTheme = createMuiTheme({
    ...defaultLightPalette,
    typography: {
        h1: {
            fontSize: "2.8rem"
        },
        h2: {
            fontSize: "2.4rem"
        },
        h3: {
            fontSize: "2.1rem"
        },
        h4: {
            fontSize: "1.8rem"
        },
        h5: {
            fontSize: "1.5rem"
        },
        h6: {
            fontSize: "1.2rem"
        }
    }
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    images: {
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#ffcccc"

    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),

    },
    zoneBar: {
        marginBottom: theme.spacing(2)
    },
    bordered: {
        width: '50%'
    },
    slider: {
        color: 'rgba(0,0,0,1)',
    },
    collection: {
        padding: theme.spacing(5)
    },
    navLink: {
        color: theme.palette.primary.contrastText,
        textDecoration: 'inherit'
    }
}));

// export default withStyles(useStyles)(Collection);
