import React, {useState} from "react";
import {Switch, Route, NavLink, useRouteMatch, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {ThemeProvider, createMuiTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";

import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import Collection from "./Collection.jsx";
import Collections from "./CollectionList.jsx";
import ObjectCreate from "./ObjectCreate.jsx";
import CollectionTypes from "./CollectionTypes";

const CollectionsRouter = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    // TODO improve breadbrumb
    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme}>
            <Switch>
                <Route exact path="/collections" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t("HomePageLink")}
                        </Link>
                    </Breadcrumbs>
                    <Collections />
                </Route>
                <Route path="/collections/types">
                    <CollectionsBreadCrumbs current={<Typography>{t("Current")}</Typography>} />
                    <CollectionTypes />
                </Route>
                <Route path="/collections/object/create">
                    <CollectionsBreadCrumbs current={<Typography>{t("CreateObject")}</Typography>} />
                    <ObjectCreate />
                </Route>
                <Route path="/collections/:collectionId">
                    <CollectionsBreadCrumbs current={<Typography>{t("Current")}</Typography>} />
                    <CurrentCollection />
                </Route>
            </Switch>

        </ThemeProvider>
    </Container>;
};

function CurrentCollection() {
    const { collectionId } = useParams();
    return <Collection collectionId={collectionId} />
}

function CollectionsBreadCrumbs({current}) {
    const classes = useStyles();
    const {t} = useTranslation();
    return <Breadcrumbs separator="/">
        <Link to="/" component={NavLink} className={classes.Link}>
            {t("HomePageLink")}
        </Link>
        <Link to="/collections/" component={NavLink} className={classes.Link}>
            {t("Collection")}
        </Link>
        {current && current}
    </Breadcrumbs>
}

// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default CollectionsRouter;
