import React, {useEffect, createRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";

import {useTranslation} from "react-i18next";
import {createImage, fetchCollectionImages, selectCollectionRootImages, selectImagesVersions} from "../../store/reducers/imagesSlice";

import {selectCollectionById} from "../../store/reducers/collectionsSlice";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CollectionsIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

import Slider from "@material-ui/core/Slider";
import CollectionImage from "./CollectionImage.jsx";

import CollectionImagesDnD from "./DnD/CollectionImageDnD.jsx";
import EditIcon from '@material-ui/icons/Edit';
import ViewComfy from "@material-ui/icons/ViewComfy";
import ViewModule from "@material-ui/icons/ViewModule";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import AttachFileIcon from "@material-ui/icons/AttachFile";

export default function CollectionImages({collectionId, editing}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const [displayMode, setDisplayMode] = useState('grid');
    const [cellSize, setCellSize] = useState(2);
    const rootImages = useSelector((state) => selectCollectionRootImages(state, collectionId));
    const fileInput = createRef();
    const handleDisplayMode = (event, mode) => {
        setDisplayMode(mode);
    };
    const handleCellSize = (event, size) => {
        setCellSize(size);
    };
    const getZoneSize = () => {
        const imageSize = (cellSize * 25 + 50);
        return 60 + imageSize * 2;
    };

    useEffect(()=>{
        dispatch(fetchCollectionImages(collectionId));
    }, [collectionId, dispatch]);

    // nicer way to store file in filereader
    // TODO : add in index
    FileReader.prototype.setFile = function(file) {
        this.file = file;
        return this;
    };
    // sending uploaded photos
    const handleUploadPhotos = () => {
        for (let i=0; i<fileInput.current.files.length; i++) {
            let file = fileInput.current.files.item(i);
            let reader = new FileReader().setFile(file);
            reader.onload = function(e) {
                let parameters = {
                    data: 'data:'+this.file.type+';base64,'+window.btoa(this.result),
                    name: this.file.name,
                    size: this.file.name,
                    collectionId: collectionId
                };
                dispatch(createImage(parameters)); // let the middleware make the proper nesting
            };
            reader.readAsBinaryString(file);
        }
    };
    const handleAddPhoto = () => {

    };
    return (
        <CardContent className={classes.images}>
            <Grid direction="row" justify="space-between" alignItems="flex-start" container
                  className={classes.zoneBar}>
                <Grid direction="row" justify="flex-start" spacing={2} item container xs>
                    <Grid item>
                        <Typography variant="h5">{t('CollectionImages')}</Typography>
                    </Grid>
                </Grid>
                <Grid direction="row" spacing={2} justify="flex-end" item container xs>
                    {
                        editing
                            ?   <Grid item>
                                <ToggleButtonGroup size="small" value={null}>
                                    <ToggleButton value="camera" size="small"  style={{height: 40}} onClick={handleAddPhoto}>
                                        <label htmlFor={collectionId+"fileInput"} style={{display: 'inline'}}>
                                            <AttachFileIcon />
                                        </label>
                                        <input ref={fileInput} id={collectionId+"fileInput"} type="file" style={{display: 'none'}} multiple={true} onChange={handleUploadPhotos} />
                                    </ToggleButton>
                                    <ToggleButton value="file" size="small"  style={{height: 40}} onClick={handleAddPhoto}>
                                        <AddAPhotoIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            :   null
                    }
                    <Grid item>
                        <ToggleButtonGroup size="small" exclusive value={displayMode} onChange={handleDisplayMode}>
                            <ToggleButton value="grid" style={{height: 40}}>
                                <ViewComfy fontSize="small"/>
                            </ToggleButton>
                            <ToggleButton value="scrollable" style={{height: 40}}>
                                <ViewModule fontSize="small"/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item>
                        <Slider
                            style={{width: 100}}
                            className={classes.slider}
                            defaultValue={cellSize}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            onChange={handleCellSize}
                            step={1} marks min={1} max={5}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                wrap="wrap"
                justify="flex-start"
                className={displayMode === "scrollable" ?  classes.scrollable : null}
                style={displayMode === "scrollable" ?  {height: getZoneSize()} : null}
            >
                {
                    rootImages.map((image, index) => (
                        !editing ?
                            <Grid item key={image.id} >
                                <CollectionImage
                                    imageId={image.id}
                                    imageSize={cellSize}
                                    isEditing={editing}
                                />
                            </Grid>
                            :
                            <Grid item key={image.id} >
                                <CollectionImagesDnD
                                    imageId={image.id}
                                    name={image.id}
                                >
                                    <CollectionImage
                                        imageId={image.id}
                                        imageSize={cellSize}
                                        isEditing={editing}
                                    />
                                </CollectionImagesDnD>
                            </Grid>
                    ))
                }
            </Grid>
        </CardContent>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    images: {
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#ffcccc"

    },
    zoneBar: {
        marginBottom: theme.spacing(2)
    },
    bordered: {
        width: '50%'
    },
    slider: {
        color: 'rgba(0,0,0,1)',
    },
    scrollable: {
        overflow: 'auto'
    }
}));