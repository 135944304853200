import React, { useState } from 'react';
import clsx from 'clsx';

import { useSelector } from "react-redux";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";

import Drawer               from '@material-ui/core/Drawer';
import AppBar               from '@material-ui/core/AppBar';
import CssBaseline          from '@material-ui/core/CssBaseline';
import Divider              from '@material-ui/core/Divider';
import IconButton           from '@material-ui/core/IconButton';

import MenuIcon             from '@material-ui/icons/Menu';
import ChevronLeftIcon      from '@material-ui/icons/ChevronLeft';

import {NavigationItems, NavigationMain, NavigationToolbar} from "./Navigation.jsx";
import {getCurrentUser} from "../store/reducers/currentUserSlice";


const drawerWidth = 240;

export default function() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const currentUser = useSelector(getCurrentUser);
    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    return <Router>
        <div className={classes.root}>
            <CssBaseline />

            {/* top navigation bar */}
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <NavigationToolbar menuOpener={
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                } />
            </AppBar>

            {/* top navigation drawer */}
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <NavigationItems currentUser={currentUser} />
            </Drawer>

            {/* main content */}
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                <NavigationMain/>
            </main>
        </div>
    </Router>;
}



// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        //background: 'linear-gradient(0deg, #4E4E4E 0%, #B4B4B4 100%)',
        //backgroundAttachment: 'fixed'
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.contrastText,
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    menuButton: {
        // marginRight: 36,
    },
    content: {
        flexGrow: 1,
        paddingTop: theme.spacing(2),
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));