// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

// const PROTOCOL = "protocol" in window.location ? window.location.protocol : 'http:';
// export const SERVER = PROTOCOL + "//"+process.env.REACT_APP_API_URL;
export const SERVER = process.env.REACT_APP_API_URL;
export default async function client(endpoint, { body, ...customConfig } = {}) {
    const headers = {
        'Content-Type': 'application/json'
    };

    const config = {
        method: body ? 'POST' : 'GET',
        //cache: 'no-cache',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
            'Cache': 'no-cache'
        },
        credentials: "include"
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    let data;
    try {
        const response = await window.fetch(endpoint, config);
        data = await response.json();
        if (response.ok) {
            return data;
        }
        throw new Error(response.statusText);
    } catch (err) {
        return Promise.reject(err.message ? err.message : data);
    }
}

client.get = function (endpoint, customConfig = {}) {
    return client(SERVER+endpoint, { ...customConfig, method: 'GET' });
};

client.delete = function (endpoint, customConfig = {}) {
    return client(SERVER+endpoint, { ...customConfig, method: 'DELETE' });
};

client.post = function (endpoint, body, customConfig = {}) {
    return client(SERVER+endpoint, { ...customConfig, body });
};
