import React, {useState, useEffect, createRef, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { NavLink, useParams} from "react-router-dom";

import {
    addNewSurvey,
    fetchSurveyById,
    selectSurveyById
} from "../../store/reducers/surveysSlice";


import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import client_ai from '../../api/client_ai';
import client from '../../api/client';


const Survey = () => {
    const { surveyId } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const fileInput = createRef();
    const {t, i18n} = useTranslation();
    const form = useRef(null)

    const survey = useSelector(state => selectSurveyById(state, surveyId));

    useEffect(()=>{
        dispatch(fetchSurveyById(surveyId));
    }, [surveyId, dispatch]);

    return <Card>
        <CardHeader title={t('CreateNewSurvey')} />
        <CardContent>
            {/* TODO: form here */}
            <Grid container spacing={2} justify={"center"}>
                <Grid md={4} sm={6} xs={12} item>
                    {
                        <img src={ survey ? survey.search.image.url : null} style={{ width: '100%', height: 'auto'}}   />
                    }
                </Grid>
              </Grid>
              <Grid container spacing={2} justify={"center"}>
                <Grid md={4} sm={6} xs={12} item>
                  <h3>{t('SurveySettings')}</h3>
                    <h4>{survey ? survey.name : null}</h4>
                    <Divider variant="middle" />
                    {survey ? survey.target.map((value, index) => {return <div key={index} style={{ margin: '10px'}}>{value.title}</div>})  : null}
                    <h3>{t('SearchTerms')}</h3>
                    <div>
                    {survey ? survey.search.terms.map((value, index) => {return <div key={index} style={{ margin: '10px'}}>{value}</div>})  : null}
                    </div>
                </Grid>
            </Grid>
            <h3>{t('Surveillance last hits')}</h3>
            <Grid container  justify={"center"}>
              {survey && survey.images ? survey.images.map((val, index)=>{
                  return <>
                  <Grid md={3} sm={4} xs={12} item style={{padding:'10px', margin:'10px'}}>
                    <Card elevation={3} style={{height:'220px'}}>
                        <CardHeader style={{textTransform: "capitalize", height: "20px", overflow: "hidden"}} title={<Typography>{val.data.title}</Typography>} />
                        <CardContent style={{textAlign:'center'}}>
                        <a href={val.data.url} target="_blank">
                            <img style={{maxHeight: '100px'}} src={val.img}/>
                        </a>
                        <div>Prix: {val.data.price}€</div>
                        </CardContent>
                    </Card>
                  </Grid>
                  </>
                }) : null
              }
          </Grid>
        </CardContent>
   </Card>;
};

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    input: {
        marginTop: ".5rem",
        width: '100%'
    }
}));

export default Survey;
